// NB: to build a cordova app, relative path is needed here
var _require = require('../../src/pages/pagesKeys'),
    HOME_PAGE_KEY = _require.HOME_PAGE_KEY,
    LIST_PAGE_KEY = _require.LIST_PAGE_KEY,
    LIST_GROUPS_PAGE_KEY = _require.LIST_GROUPS_PAGE_KEY;

var dataConfig = require('./dataConfig');

var projectConfig = require('../../../data/config.js');

var _require2 = require('./profiles'),
    DEFAULT_PROFILE = _require2.DEFAULT_PROFILE;

var _require3 = require('./dataConfig'),
    DATA_TYPE_EVENT_CATEGORIES = _require3.DATA_TYPE_EVENT_CATEGORIES,
    DATA_TYPE_EVENTS = _require3.DATA_TYPE_EVENTS; // Only export a default object, to be able to
// override properties depending on target environment


module.exports = {
  // Misc UI
  DELAY_BEFORE_CLICK_ACTION: 50,
  //ms
  SHOW_TERMS_OF_USE_ON_HOME: true,
  // Terms of use URL
  TOU_LINK: {},
  // App profiles
  SUPPORTED_PROFILES: [DEFAULT_PROFILE],
  DEFAULT_PROFILE: DEFAULT_PROFILE,
  // Routing (in two columns mode, = default page on left column)
  getHomePage: function getHomePage(profile) {
    switch (profile) {
      case DEFAULT_PROFILE:
        return {
          pageKey: HOME_PAGE_KEY,
          props: null
        };

      /*
      case WEBAPP_EXH_PROFILE: return {
          pageKey: LIST_PAGE_KEY,
          props: { inputs: [{ dataType: dataConfig.DATA_TYPE_EXHIBITOR_CATEGORIES }] },
      }
       case WEBAPP_EVT_PROFILE: return {
          pageKey: LIST_GROUPS_PAGE_KEY,
          props: { inputs: [{ dataType: dataConfig.DATA_TYPE_EVENTS }] },
      }*/

      default:
        console.error('Bad configuration, unexpected profile: ' + profile);
    }
  },
  APP_TITLE_SEPARATOR: ' - ',
  ROUTE_SEPARATOR: '?',
  // Lang
  SUPPORTED_LANGS: ['fr'],
  DEFAULT_LANG: 'fr',
  // Backend / Updates
  UPDATE_ENABLED: true,
  BO_SSH_USER: 'www',
  LIST_GROUPS_SHOW_SIDEINDEX_IF_ABOVE: 20,
  // Map
  MOBIGEO_API_KEY: 'oR9voo2k',
  // Mobile-Spot link
  //    MOBILESPOT_WWW: 'http://www.mobile-spot.com',
  // SHARE:
  DEFAULT_SHARE_IMAGE: '',
  //  on LinkedIn
  LINKEDIN_API_KEY: '',
  //  on Facebook
  FACEBOOK_APP_ID: '',
  WEB_URL: projectConfig.web.url,
  // In web mode, this fallback url can be used if the user's
  // browser does not meet some requirements (eg IE<11)
  WEB_FALLBACK: '',
  // For WK webview resources access
  // @see doc on google drive: R&D/Migration WK webview: https://docs.google.com/spreadsheets/d/1sIfm3LgBq1Zgp7LH2-mKSD1vrL6VphNR1mkiHZ73zHM/edit?usp=drive_web&ouid=101003723454836243359
  CUSTOM_URL_SCHEME: projectConfig.customFileUrlScheme,
  // e.g com.printemps.haussmann.dev
  NATIVE_ID: projectConfig.native.id,

  /**
   * FEATURES
   */
  DOWNLOAD_FAVORITES: {
    FEATURE_ENABLED: false
  },
  APPOINTMENT_REQUEST: {
    FEATURE_ENABLED: false // webservice url is defined per environment

  },
  CONTRIBUTIONS_FEED: {
    FEATURE_ENABLED: false
  },
  FLIGHTS_SCHEDULE: {
    FEATURE_ENABLED: false
  },
  LOGIN: {
    FEATURE_ENABLED: false,
    // webservice url is defined per environment
    forgottenPasswordURL: null,
    createAccountURL: null,
    tosURL: null
  },
  POLL: {
    FEATURE_ENABLED: false
  },
  PROFILE: {
    FEATURE_ENABLED: false
  },
  SHARE: {
    FEATURE_ENABLED: true,
    POSITION_SHARE_IMAGE: '',
    // Declare restrictions
    // If current page + current profile are found below,
    // then share button is hidden.
    RESTRICTIONS: [// e.g
      // { pageKey: EXHIBITOR_PAGE_KEY,
      //   for : [ VISITOR_GP_PROFILE ]
      // }
    ]
  },
  NOTES: {
    FEATURE_ENABLED: true,
    RESTRICTIONS: [// e.g
      // { pageKey: EXHIBITOR_PAGE_KEY,
      //   for : [ VISITOR_GP_PROFILE ]
      // }
    ]
  },
  SYNCHRONIZED_FAVORITES: {
    FEATURE_ENABLED: false,
    PUBLIC_KEY: 'eN/sCwOHNN+42fjwDoLj35xaVN0OgnXsxzV8+yvu0oy0Ea3Klk19j3EEN3uPZkw3HR8HlEpOSg999rsBwwklDv/BoRNt8RhYZoCyDfmcfhusV/w8go7jtw6JI4q4P8nfB6QgotAfpAYG/ijCOzbbXI/WG1uppA2DP8R6vb7d7WM=',
    UNAUTHORIZED_MODE_ENABLED: true,
    // webservice url is defined per environment
    // Debouncing value represents the delay between
    // last favorite toggle (add or remove) and server call
    DEBOUNCING: 10 * 1000 // ms

  },
  SOCIAL: {
    FEATURE_ENABLED: true
  },
  SYNOPTIC_AGENDA: {
    AUTOFOCUS_TAB: true,
    TAB_MIN_WIDTH: 120,
    TAB_MAX_WIDTH: 200,
    SYNOPTIC_DEFAULT_EVENT_BGCOLOR: '#b4b5b4',
    DEFAULT_INPUT: {
      agendaProps: {
        input: {
          parentId: 1,
          parentType: DATA_TYPE_EVENT_CATEGORIES,
          dataType: DATA_TYPE_EVENTS
        }
      }
    },
    getCountDependingOnWidth: function getCountDependingOnWidth(clientWidth) {
      if (clientWidth < 800) {
        return 3;
      }

      if (clientWidth < 1280) {
        return 4;
      }

      return 5;
    }
  },
  WEB_PUSH: {
    FEATURE_ENABLED: true,
    DEFAULT_NOTIFICATION_TITLE: projectConfig.name,
    DEFAULT_NOTIFICATION_IMAGE: '',
    // url of notification icon
    AUTO_SUBSCRIBE: true,
    // automatically prompt user once app is loaded
    SUBSCRIBE_WIDGET_ENABLED: false // show bell icon (style is totally configurable and must be extracted if needed)

  },
  iosAppStoreUrl: projectConfig.native.iosAppStoreUrl,
  playStoreUrl: projectConfig.native.playStoreUrl,
  name: projectConfig.native.name.default,
  author: projectConfig.native.author
};