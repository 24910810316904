
import React        from 'react';
import PropTypes    from 'prop-types';
import { debounce } from 'lodash-custom';

import { SEARCH_MIN_CHARS } from 'data/config/searchConfig';

import SearchPlaceTypes from './SearchPlaceTypes';
import SearchField from 'src/components/search/SearchField';

import { USER_POSITION } from 'src/pages/map/mapUtil';

import './SearchPlaceRow.scss';


const LOG_PREF = '[PlaceSearchRow] ';


/**
 * Return field to allow the user to SEARCH FOR A POI/PLACE
 * @param  {string} type (see SearchPlaceTypes)
 * @param  {string} placeholder
 * @param  {boolean} userIsLocated
 * @param  {function} _clear
 * @param  {object} labels
 * @param  {object} actions
 */
function getSearchField(type, placeholder, userIsLocated, _clear, labels, actions) {

    // Trigger search
    const _perform = debounce(string => {
        actions.performPlaceSearch(string, type);
    }, 400);

    function _setUserPositionAsStart() {
        actions.searchedPlaceSelected(SearchPlaceTypes.start, {
            type: USER_POSITION
        });
    }

    return (
        <div>
            <SearchField
                placeholder={placeholder}
                clearResults={_clear}
                perform={_perform}
                minChars={SEARCH_MIN_CHARS} />

            { type === SearchPlaceTypes.start && userIsLocated &&
                <div className="sp-btn-position" onClick={_setUserPositionAsStart}><span>{ labels.location.yourLocation }</span></div>
            }
        </div>
    );
}

/**
 * Return INFORMATION about the SELECTED POI/PLACE
 * @param  {object} selectedEntry
 * @param  {function} _clear
 * @param  {object} labels
 */
function getSelectedEntryInformation(selectedEntry, _clear, labels) {

    let selectedEntryText;
    if (selectedEntry.type === USER_POSITION) {
        selectedEntryText = labels.location.yourLocation;
    } else {
        selectedEntryText = selectedEntry.text;
    }

    return (
        <div className="ps-selected">
            <div className="ps-selected-text">
                <div title={selectedEntryText}>{selectedEntryText}</div>
                { selectedEntry.textMinor &&
                    <div className="text-minor" title={selectedEntry.textMinor} dangerouslySetInnerHTML={{ __html: selectedEntry.textMinor }} />
                }
            </div>
            <div className="ps-edit-button fa fa-pencil" onClick={_clear} />
        </div>
    );
}


const SearchPlaceRow = ({ type, labels, searchPlacePlaceholder, actions, selectedEntry, userIsLocated }) => {

    let title;
    switch(type) {
        case SearchPlaceTypes.start:
            title = labels.placeSearch.start;
            break;

        case SearchPlaceTypes.dest:
            title = labels.placeSearch.destination;
            break;

        case SearchPlaceTypes.poi:
            title = labels.placeSearch.poi;
            break;

        default:
            console.error(LOG_PREF+'Invalid type '+type);
            return null;
    }

    // Reset input and results
    function _clear() {
        actions.clearPlaceResults(type);
    }

    let hasSelectedEntry = selectedEntry && selectedEntry.type;

    return (
        <div className="ps-row">
            <div className="ps-bar horizontal-separator">{title}</div>
            { !hasSelectedEntry
                ? getSearchField(type, searchPlacePlaceholder, userIsLocated, _clear, labels, actions)
                : getSelectedEntryInformation(selectedEntry, _clear, labels)
            }
        </div>
    );
};

SearchPlaceRow.propTypes = {
    type   : PropTypes.oneOf(Object.keys(SearchPlaceTypes).map(key => SearchPlaceTypes[key])),
    labels : PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    searchPlacePlaceholder: PropTypes.string,
    selectedEntry: PropTypes.object,
    userIsLocated: PropTypes.bool,
};

export default SearchPlaceRow;
