/**
 * Note: This module is from old cake framework, and was named web_app.updater
 */

// Conf
import config from 'data/config/config';

// App modules
import fetchHelper from 'src/core/util/FetchHelper';
import { get3CharsLang } from 'src/core/data-and-assets/utils';
import assetsFactory from 'src/core/data-and-assets/assetsFactory';
import bufferFactory from 'src/core/data-and-assets/bufferFactory';
import FileSystemHelper from 'src/core/data-and-assets/FileSystemHelper';
import * as Db from 'src/core/data-and-assets/Db';
import * as DataAssetsUtil from 'src/core/data-and-assets/DataAssetsUtil';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { getBindedActions } from 'src/store/bindedActions';
import showConfirmModal from 'src/core/util/showConfirmModal';

import { DATA_AUTO_UPDATE } from 'data/config/dataConfig';

import {
    get as getLabels,
    getCurrent as getCurrentLang,
} from 'src/core/Lang';

const LOG_PREF = '[Updater] ';

let assets = null,
    buffer = null,
    inProgress = false,
    _isInitialized = false,
    _forcedCanceled = false,
    _filesToDownload,
    _filesRemoteDatas,
    readyToCommit = false,
    _noSurprisesTimer = null,
    NEW_FILES_URL = 'manifest/phonegap?pixel_ratio=1',
    TIMEOUT = 20 * 1000, //eslint-disable-line no-unused-vars
    NO_SURPRISES_TIMEOUT = 1000 * 10 * 60,
    ASSETS_VERSION,
    ASSETS_DEFINITION_FILE;


export const getAssets = () => assets;

export const getAssetsDefinitionFileUrl = () => DataAssetsUtil.getUrl(ASSETS_DEFINITION_FILE);


/**
 * Get the url of the latest version of an asset
 * @param  {string} url
 */
export const getUrl = (url) => {

    let returnedValue;

    let _url = (url[0] === '/') ? url : '/' + url;

    let indexParams = _url.lastIndexOf('?');
    if (indexParams !== -1) {
        _url = _url.substr(0, indexParams);
    }
    // not ready yet
    if (!assets || _isInitialized === false) {
        returnedValue = null;
    } else {
        let asset = assets.getItem(_url);
        if (!asset || !asset.version) {
            returnedValue = null;
        } else {
            // url exist in the assets and is a valid asset
            returnedValue = FileSystemHelper.getPath('V' + asset.version + _url);
        }
    }
    return returnedValue ? returnedValue : url;

};


/**
 * Initialize this module
 * @param  {function} callback
 */
export const init = (callback) => {
    console.log(LOG_PREF + 'init');

    if (global.isCordovaContext !== true) {
        console.error(LOG_PREF + 'A Cordova context is needed in order to use Updater');
        return;
    }

    inProgress = false;
    _isInitialized = false;
    ASSETS_VERSION = 'global';
    // TODOLATER: why use 'js' folder ? Better to use 'offline'... but can break apps
    // change also the 'assets' task in deploy.rb
    ASSETS_DEFINITION_FILE = 'assets_' + ASSETS_VERSION + '.json';

    buffer = bufferFactory(FileSystemHelper);
    assets = assetsFactory(FileSystemHelper, getAssetsDefinitionFileUrl, getCurrentLang);

    global.assets = assets;

    buffer.init();
    assets.init(() => {
        console.log(LOG_PREF + 'initialized');
        _isInitialized = true;
        getBindedActions().updaterInitialized();
        if (callback) {
            callback();
        }
    });
};


let modalDisplayed = {};

// `what` could be: timeout / error / abord / notmodified / parsererror
const _userInteraction = (when, what) => {
    console.log(LOG_PREF + '_userInteraction ' + when);

    if (modalDisplayed[when]) {
        // Skip new modal display if already visible
        return;
    }

    if (when === 'remoteCheckUpdate') {
        // MA: maybe not a good idea, disable for now
        //WebApp.ui.display_msg(WebApp.i18n.t('no_connection','updater'), 3000);

    /* Never called
    } else if (when === 'showCancelUpdate') {
        modalDisplayed.showCancelUpdate = true;

        showConfirmModal({
            title: getLabels().update.modalTitle,
            message: getLabels().update.cancel_update,
            anywayCb: function() {
                modalDisplayed.showCancelUpdate = false;
            },
            yesCb: function() {
                _forcedCanceled = true;
                downloadRemoteEnd();
            },
        });
    */

    } else if (when === 'updateProgressBar') {
        setProgress(what);

    } else if (when === 'clearProgress') {
        clearProgress();

    } else if (when === 'askForUpdate') {
        function proceedToUpdate() {
            downloadRemoteInit(what);
            downloadRemoteFiles();
        }

        if (DATA_AUTO_UPDATE) {
            console.log(LOG_PREF, 'Data update launched automaticaly');
            proceedToUpdate();
        } else {
            let size_txt;
            if (what.size < 1024) {
                size_txt = what.size + getLabels().size.bytes;
            } else if (what.size < (1024 * 1024)) {
                size_txt = (Math.ceil(what.size / 1024 * 100) / 100) + getLabels().size.kilobytes;
            } else {
                size_txt = (Math.ceil(what.size / 1024 / 1024 * 100) / 100) + getLabels().size.megabytes;
            }

            modalDisplayed.askForUpdate = true;
            showConfirmModal({
                title: getLabels().update.modalTitle,
                message: getLabels().update.update_detected + ` (${size_txt})`,
                anywayCb: function() {
                    modalDisplayed.askForUpdate = false;
                },
                yesCb: function() {
                    console.log(LOG_PREF, 'Data update granted by user');
                    proceedToUpdate();
                },
                noCb: function() {
                    console.log(LOG_PREF, 'Data update denied by user');
                    inProgress = false;
                },
            });
        }
    } else if (when === 'updateComplete') {
        modalDisplayed.updateComplete = true;
        getBindedActions().showNotification({
            message: getLabels().update.update_done,
            // title: getLabels().update.modalTitle,
            duration: 2, // sec
            cb: () => { modalDisplayed.updateComplete = false; }
        });
    }
    // Never happens:
    else if (when === 'newAppVersion') {
        modalDisplayed.newAppVersion = true;
        getBindedActions().showNotification({
            message: getLabels().update.new_version_detected,
            // title: getLabels().update.modalTitle,
            cb: () => { modalDisplayed.newAppVersion = false; }
        });
    } else if (when === 'timeout') {
        modalDisplayed.timeout = true;
        getBindedActions().showNotification({
            message: getLabels().update.timeout,
            // title: getLabels().update.modalTitle,
            cb: () => { modalDisplayed.timeout = false; },
            level: NotificationLevels.WARNING,
        });
    }
};

const beginUpdate = (datas) => {
    console.log(LOG_PREF + 'beginUpdate');
    _userInteraction('askForUpdate', datas);
};

const buildRessourcesInfos = () => {
    console.log(LOG_PREF + 'buildRessourcesInfos');

    let allAssets = assets.getItems();
    for (let file in buffer.list) {
        if (buffer.list.hasOwnProperty(file) === false) {
            continue;
        }

        allAssets[file] = buffer.list[file];
    }
    return allAssets;
};


/**
 * Launch update process
 */
export const startUpdate = () => {

    if (config.UPDATE_ENABLED !== true) {
        return;
    }

    console.log(LOG_PREF + 'startUpdate');

    if (_isInitialized === false) {
        console.log(LOG_PREF + 'startUpdate: please initialize Updater first.');
        return false;
    }

    if (inProgress === true) {
        console.log(LOG_PREF + 'startUpdate: already in progress.');
        return false;
    }
    inProgress = true;

    // MA: force inProgress to false after 10 min... because popup could be erased
    clearTimeout(_noSurprisesTimer);
    _noSurprisesTimer = setTimeout(() => {
        inProgress = false;
    }, NO_SURPRISES_TIMEOUT);

    let ressources = buildRessourcesInfos();
    remoteCheckUpdate(ressources, beginUpdate);

    return true;
};

function remoteCheckUpdate(ressources, callback) {
    console.log(LOG_PREF + 'remoteCheckUpdate');

    let params = {
        db_version: Db.getVersion(),
        db_schema: Db.getSchema(),
        files: JSON.stringify(ressources),
        locale: get3CharsLang(getCurrentLang()),
        assets_version: ASSETS_VERSION,
        localVersion: assets.getListVersion()
    };

    // convert params as form data (expected by manifest controller)
    let formData = new FormData();
    for (let key in params) {
        if (params.hasOwnProperty(key) === true) {
            formData.append(key, params[key]);
        }
    }

    console.info('updater request params: ', params);
    fetchHelper(DataAssetsUtil.getUrl(NEW_FILES_URL + '&force=1&time=' + new Date().getTime(), true), {
            body: formData,
            method: 'POST',
        },
        true, // json
        (datas) => {
            try {
                console.info(LOG_PREF + 'update response:', datas);

                if (datas.NEW_VERSION) {
                    _userInteraction('newAppVersion');
                    inProgress = false;
                } else if (datas.nb_files === 0 && datas.data === false) {
                    // Up to date
                    assets.updateVersion(datas.version);
                    inProgress = false;
                } else if (typeof callback === 'function') {
                    // Update needed + callback provided
                    callback(datas);
                } else {
                    // Update needed but no callback provided
                    inProgress = false;
                }
            } catch (e) {
                console.error(LOG_PREF + 'Updater error while processing incoming datas.', datas);
                inProgress = false;
            }
        },
        (...args) => {
            console.error(LOG_PREF + 'remoteCheckUpdate error', args);
            _userInteraction('remoteCheckUpdate', args);
            inProgress = false;
        },
        // No modal on error
        false
    );
};

function downloadRemoteInit(datas) {
    console.log(LOG_PREF + 'downloadRemoteInit');

    readyToCommit = false;
    _forcedCanceled = false;
    _filesToDownload = [];
    _filesRemoteDatas = datas;
    if (datas.nb_files !== 0) {
        for (let file in datas.files) {
            if (datas.files.hasOwnProperty(file) === false) {
                continue;
            }
            _filesToDownload.push(file);
        }
    }

    // Inform app that data/assets update is about to start (to show a loader for instance)
    getBindedActions().dataAssetsUpdating(_filesToDownload, datas);
};

function downloadRemoteEnd() {
    console.log(LOG_PREF + 'downloadRemoteEnd');
    _userInteraction('clearProgress');
    _filesToDownload = [];
    _filesRemoteDatas = [];
    inProgress = false;
};

function downloadRemoteFiles() {
    console.log(LOG_PREF + 'downloadRemoteFiles');
    // if user canceled the update
    if (inProgress === false) {
        console.log(LOG_PREF + 'User canceled already, so just quit.');
        return;
    }

    if (_filesToDownload.length === 0) {
        console.log(LOG_PREF + 'All files have been downloaded !!');
        // user cannot cancel anymore
        _userInteraction('clearProgress');
        readyToCommit = true;
        commitNewVersion();
        return;
    }

    let fileName = _filesToDownload[0];
    let fileDatas = _filesRemoteDatas.files[fileName];

    const win = () => {
        // remove successfully downloaded file
        _filesToDownload.shift();

        let percent = (_filesRemoteDatas.nb_files - _filesToDownload.length) / _filesRemoteDatas.nb_files * 100;
        _userInteraction('updateProgressBar', percent);

        downloadRemoteFiles();
    };
    const fail = (errorType, errorCode) => {
        console.log(LOG_PREF + 'Error while downloading file: ' + errorType + ' => ' + errorCode);
        // display a message in all types of error
        if (_forcedCanceled === false) {
            _userInteraction('timeout');
        }
        // for now, quit at the first error
        downloadRemoteEnd();
    };

    buffer.downloadRemoteFile(fileName, fileDatas, win, fail);
};

function commitNewVersion() {
    console.log(LOG_PREF + 'commitNewVersion');
    let updatedAssets = _filesRemoteDatas.files ? Object.keys(_filesRemoteDatas.files) : [],
        updatedTables;

    if (readyToCommit !== true) {
        console.log(LOG_PREF + 'Bad context');
        // cancel everything
        downloadRemoteEnd();
        return;
    }

    let hasDataToUpdate = false;
    let hasFilesToUpdate = false;

    const checkIsAllUpdated = () => {
        console.log(LOG_PREF + 'checkIsAllUpdated');

        hasDataToUpdate = (_filesRemoteDatas.data !== false);
        hasFilesToUpdate = (_filesRemoteDatas.nb_files > 0);

        let isFinished = !hasDataToUpdate && !hasFilesToUpdate;
        console.log(LOG_PREF + 'hasDataToUpdate: ' + hasDataToUpdate + ' / hasFilesToUpdate: ' + hasFilesToUpdate);

        if (isFinished === true) {
            inProgress = false;
            console.log(LOG_PREF + 'Update is applied!');
            _userInteraction('updateComplete');
            getBindedActions().dataAssetsUpdated(updatedTables, updatedAssets);
        }
    }

    checkIsAllUpdated();

    if (hasFilesToUpdate === true) {
        console.log(LOG_PREF + 'updating files!');
        buffer.commitBuffer(assets.version, (success) => {
            console.log(LOG_PREF + 'commitBuffer done!', success);
            if (!success) {
                // cancel if error
                downloadRemoteEnd();
            } else {
                assets.updateAssets(buffer);
                _filesRemoteDatas.nb_files = 0;
                checkIsAllUpdated();
            }
            buffer.clearList();
        });
    }

    if (hasDataToUpdate === true) {
        console.log(LOG_PREF + 'updating db!');
        Db.refresh((status, _updatedTables) => {
            _filesRemoteDatas.data = false;
            updatedTables = _updatedTables;
            checkIsAllUpdated();
        });
    }

    assets.updateVersion(_filesRemoteDatas.version);
};

function setProgress(percent) {
    console.log(LOG_PREF + `setProgress ${percent}%`);
};

function clearProgress() {
    console.log(LOG_PREF + 'clearProgress');
    getBindedActions().hideFullLoader();
};



/**
 * Delete information stored in localstorage
 */
export const clearLocalStorage = () => {
    console.log(LOG_PREF + 'clearLocalStorage');
    window.localStorage.removeItem('Assets.version');
    window.localStorage.removeItem('Assets.listVersion');
};