
import { iosAppStoreUrl, playStoreUrl, name, author } from "data/config/config";
import { isIOS, isAndroid } from "src/core/util/browser";
import { getCurrent } from "src/core/Lang";

function generateMeta(attrs) {
    var meta = document.createElement('meta');
    meta.name = attrs.name;
    meta.setAttribute('content', attrs.content);
    document.getElementsByTagName('head')[0].appendChild(meta);
}

export function injectSmartBanners() {
    const hasSmartBanner = !global.isCordovaContext && (isIOS() ? !!iosAppStoreUrl : !!playStoreUrl);

    /*  see: https://github.com/ain/smartbanner.js

        <meta name="smartbanner:title" content="'+projectConfig.native.name.default+'">
        <meta name="smartbanner:author" content="'+projectConfig.native.author+'">
        <meta name="smartbanner:price" content=" ">
        <meta name="smartbanner:price-suffix-apple" content=" ">
        <meta name="smartbanner:price-suffix-google" content=" ">
        <meta name="smartbanner:icon-apple" content="./lib/img/apple-app-store.png">
        <meta name="smartbanner:icon-google" content="./lib/img/google-play.png">
        <meta name="smartbanner:button" content="VIEW">
        <meta name="smartbanner:button-url-apple" content="'+projectConfig.native.iosAppStoreUrl+'">
        <meta name="smartbanner:button-url-google" content="'+projectConfig.native.playStoreUrl+'">
        <meta name="smartbanner:enabled-platforms" content="android,ios">

        <link rel="stylesheet" href="./lib/smartbanner.min.css">
        <script src="./lib/smartbanner.min.js"></script>
    */

    if (hasSmartBanner) {
        generateMeta({ name: "smartbanner:title",  content: name, });
        generateMeta({ name: "smartbanner:author", content: author, });
        generateMeta({ name: "smartbanner:price",  content: " ", });

        if (isIOS()) {
            generateMeta({ name: "smartbanner:price-suffix-apple", content: " ", });
            generateMeta({ name: "smartbanner:icon-apple", content: "./lib/img/apple-app-store.png", });
            generateMeta({ name: "smartbanner:button-url-apple", content: iosAppStoreUrl, });
            generateMeta({ name: "smartbanner:enabled-platforms", content: "ios", });
        } else {
            generateMeta({ name: "smartbanner:price-suffix-google", content: " ", });
            generateMeta({ name: "smartbanner:icon-google", content: "./lib/img/google-play.png", });
            generateMeta({ name: "smartbanner:button-url-google", content: playStoreUrl, });
            generateMeta({ name: "smartbanner:enabled-platforms", content: "android", });
        }

        // TODO: extract labels to i18n files
        generateMeta({ name: "smartbanner:button", content: getCurrent() == "fr" ? "VOIR" : "VIEW" });

        var linkElement = document.createElement("link");
        linkElement.setAttribute("rel", "stylesheet");
        linkElement.setAttribute("href", "./lib/smartbanner.min.css");
        document.getElementsByTagName("head")[0].appendChild(linkElement);

        var script = document.createElement("script");
        script.setAttribute("src", "./lib/smartbanner.min.js");
        script.setAttribute("type", "text/javascript");
        document.getElementsByTagName("body")[0].appendChild(script);
    }
}
