
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Page } from 'react-onsenui';
import { CSSTransition } from 'react-transition-group';

import config from 'data/config/config';

import {
    CATEGORIES_DATA_TYPE,
    CATEGORIES_MAPPING
} from 'data/config/dataConfig';

import { SEARCH_MIN_CHARS } from 'data/config/searchConfig';

import {
    FAVORITES_PAGE_KEY,
    LIST_PAGE_KEY,
    LIST_GROUPS_PAGE_KEY,
    SEARCH_PAGE_KEY,
    SYNOPTIC_AGENDA_PAGE_KEY,
} from 'src/pages/pagesKeys';

import SearchField from 'src/components/search/SearchField';
import { isIphoneX, isIpad } from 'src/core/util/browser';

// import {
//     STATUS as SYNCHRO_FAV_STATUS,
// } from 'src/core/favorites/SynchronizedFavoritesService';

// import SearchPlaceTypes from 'src/components-standalone/search-place-dialog/SearchPlaceTypes';

import './AppToolbar.scss';


// Warning: this value is defined in ./AppToolbar.scss, but also here due to computation issues
// (sometimes offsetHeight: 0 despite a call to requestAnimationFrame after component render/update)
const TOOLBAR_HEIGHT_FALLBACK = isIphoneX() ? 92 : 48;

const getButton = (iconClassName, iconStyle, action, label) => (
    <span className="app-toolbar-icon" onClick={action} title={label}>
        <span className={iconClassName} style={iconStyle} />
    </span>
);

class AppToolbar extends PureComponent {

    state = {
        childrenStyle: null,
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (this.props.searchResults && !nextProps.searchResults) {
            this.clearSearchResults()
        }
    }

    navigateToHome = () => {
        let homePage = config.getHomePage(this.props.profile);
        this.props.actions.navigate(homePage.pageKey, homePage.props);
    }

    navigateToFavoritesPage = () => {
        this.props.actions.navigate(FAVORITES_PAGE_KEY);
    }

    navigateToSynopticAgendaPage = () => {
        this.props.actions.navigate(SYNOPTIC_AGENDA_PAGE_KEY, this.props.agendaProps ? { agendaProps: this.props.agendaProps } : null);
    }

    navigateBackToAgendaPage = () => {
        this.props.actions.navigate(LIST_GROUPS_PAGE_KEY, this.props.agendaProps);
    }

    handleSearchButtonClick = () => {
        if (this.props.contextualDataTypes) {
            this.showSearchField();
        } else {
            this.props.actions.navigate(SEARCH_PAGE_KEY);
        }
    }

    handleFilterButtonClick = () => {
        this.props.actions.showFilterDialog(this.props.contextualDataTypes);
    }

    showSearchField = () => {
        this.setState({ searchFieldVisible: true });
    }
    hideSearchField = () => {
        this.setState({ searchFieldVisible: false });
    }
    clearSearchResults = () => {
        this.hideSearchField();
        if (typeof this.props.onSearchClear === 'function') {
            this.props.onSearchClear();
        }
    }

    performSearch = str => {
        this.props.actions.performSearch(str, this.props.pageKey, this.props.contextualDataTypes, true);
    }

    openMenu = () => {
        // this.hideSearchField();
        this.props.actions.openMenu(this.props.pageKey);
    }

    setWrapperEl = el => {
        this.wrapperEl = el;
    }

    setToolbarEl = el => {
        this.toolbarEl = el;
    }

    updateChildrenStyle = () => {
        let style;

        if (this.wrapperEl && this.toolbarEl) {
            const
                pageTop = this.wrapperEl.parentNode.offsetTop,
                contentTop = this.toolbarEl.offsetHeight || TOOLBAR_HEIGHT_FALLBACK;

            let height = window.innerHeight - contentTop - pageTop

            if (global.isCordovaContext && isIpad()) {
                const clientHeight = document.documentElement.clientHeight
                const innerHeight = window.innerHeight
                const offset = contentTop + pageTop
                height = innerHeight > clientHeight ? innerHeight - offset : clientHeight - offset
            }

            style = {
                top: contentTop,
                height
            };
        } else {
            style = null;
        }

        if (JSON.stringify(style) !== JSON.stringify(this.state.childrenStyle)) {
            this.setState({ childrenStyle: style, });
        }
    }

    componentDidMount() {
        window.requestAnimationFrame(this.updateChildrenStyle);
    }
    componentDidUpdate() {
        window.requestAnimationFrame(this.updateChildrenStyle);
    }

    getSearchFieldPlaceholder({ contextualDataTypes, labels }) {
        const placeholder = []
        if (Array.isArray(contextualDataTypes)) {
            contextualDataTypes.forEach(dataType => {
                let type = dataType

                if (CATEGORIES_DATA_TYPE.indexOf(dataType) > -1) {
                    type = CATEGORIES_MAPPING[dataType]
                }

                const text = labels.data[type].plural.toLowerCase()

                if (placeholder.indexOf(text) === -1) {
                    placeholder.push(text)
                }
            })
        }

        if (labels.search.keywords) {
            placeholder.push(labels.search.keywords)
        }

        return placeholder.join(', ')
    }


    hasRootCategoryButtonInToolbar = () => !this.state.searchFieldVisible && this.props.rootCategoryButtonInToolbar

    hasBackButton = () => this.props.hasBackButton

    hasHomeButton = () => !this.state.searchFieldVisible && this.props.hasHomeButton

    hasFavButton = () => !this.state.searchFieldVisible && this.props.hasFavButton === true

    hasSearchButton = () => !this.state.searchFieldVisible && this.props.hasSearchButton === true

    // No filter button if more than 1 datatype displayed
    hasFilterButton = () => !this.state.searchFieldVisible && this.props.hasFilterButton

    hasSynchroFavBtnStatus = () => this.props.isSynchroFavFeatureEnabled
                                    && !this.state.searchFieldVisible
                                    && this.props.synchroFavBtnStatus

    hasMenuButton = () => this.props.hasMenuButton

    hasSynopticAgendaButton = () => !this.state.searchFieldVisible && this.props.hasSynopticAgendaButton

    hasBackToAgendaButton = () => !this.state.searchFieldVisible && this.props.hasBackToAgendaButton


    getToolbar() {

        return (
            <div className={'app-toolbar'+(this.props.theme ? ' theme-'+this.props.theme : '')}
                 ref={this.setToolbarEl}>

                <div className="left">
                    { this.hasBackButton() &&
                         getButton('zmdi zmdi-arrow-back toolbar-back-btn', null, this.props.actions.navigateBack, this.props.labels.common.goBack)
                    }
                </div>

                <CSSTransition
                    in={this.state.searchFieldVisible}
                    classNames="search-field"
                    timeout={0}>

                    <SearchField
                        key={0}
                        placeholder={this.getSearchFieldPlaceholder(this.props)}
                        perform={this.performSearch}
                        clearResults={this.clearSearchResults}
                        searchResults={this.props.searchResults}
                        minChars={this.props.pageKey === LIST_PAGE_KEY ? 1 : SEARCH_MIN_CHARS} />
                </CSSTransition>

                { !this.state.searchFieldVisible &&
                    <div className="center content-font">{this.props.contextualTitle || this.props.title}</div>
                }

                <div className="right">

                    { this.hasSynopticAgendaButton() &&
                        getButton('fa fa-calendar-alt', { fontSize: '.95em' }, this.navigateToSynopticAgendaPage, this.props.labels.common.goToSynopticAgenda)
                    }

                    { this.hasBackToAgendaButton() &&
                        getButton('fa fa-calendar-week', { fontSize: '.95em' }, this.navigateBackToAgendaPage, this.props.labels.common.goBackToAgenda)
                    }

                    { this.hasRootCategoryButtonInToolbar() &&
                        getButton('fa fa-list', null, this.props.navigateToRootCategory, this.props.labels.common.goToRootCategory)
                    }

                    { this.hasHomeButton() &&
                        getButton('fa fa-home', null, this.navigateToHome, this.props.labels.common.goToHome)
                    }

                    { this.hasFavButton() === true &&
                        getButton('zmdi zmdi-star', null, this.navigateToFavoritesPage, this.props.labels.common.goToFavorites)
                    }

                    { this.hasSearchButton() === true &&
                        getButton('zmdi zmdi-search', null, this.handleSearchButtonClick, this.props.labels.common.goToSearch)
                    }

                    { this.hasFilterButton() &&
                        getButton(
                            'fa fa-filter'+(this.props.isDataFiltered ? ' filter-enabled' : ''),
                            { fontSize: '.8em' },
                            this.handleFilterButtonClick,
                            this.props.labels.common.goToFilter)
                    }

                    { this.hasSynchroFavBtnStatus() &&
                        getButton(
                            `fa fa-refresh synchro-fav-btn synchro-fav-btn-${this.props.synchroFavBtnStatus}`,
                            null,
                            this.props.actions.synchroFavoritesIconClicked,
                            ''
                        )
                    }

                    { this.hasMenuButton() === true &&
                        getButton('zmdi zmdi-menu', null, this.openMenu, this.props.labels.common.openMenu)
                    }
                </div>
            </div>
        );
    }

    setHeightOnChild = child => {
        if (!child) {
            return null;
        }
        if (this.state.childrenStyle && this.state.childrenStyle.height) {
            return React.cloneElement(child, { height: this.state.childrenStyle.height });
        }
        return child;
    }

    render() {
        // With toolbar
        if (this.props.isDisplayed !== false) {
            return (
                <Page>
                    <div className="app-toolbar-wrapper" ref={this.setWrapperEl}>

                        { this.getToolbar() }

                        <div className="app-toolbar-children" style={this.state.childrenStyle}>
                            { React.Children.map(this.props.children, this.setHeightOnChild) }
                        </div>
                    </div>
                </Page>
            );
        }
        // without toolbar
        else {
            return (
                <Page>
                    <div className="app-toolbar-wrapper">
                        <div className="app-toolbar-children">
                            {this.props.children}
                        </div>
                    </div>
                </Page>
            );
        }
    }

}

AppToolbar.propTypes = {
    labels         : PropTypes.object.isRequired,
    actions        : PropTypes.object.isRequired,
    profile        : PropTypes.string.isRequired,
    isDisplayed    : PropTypes.bool,
    title          : PropTypes.string.isRequired,
    contextualTitle: PropTypes.string,
    pageKey        : PropTypes.string,
    hasBackButton  : PropTypes.bool,
    hasMenuButton  : PropTypes.bool,
    hasHomeButton  : PropTypes.bool,
    hasFavButton   : PropTypes.bool,
    hasSearchButton: PropTypes.bool,
    hasFilterButton: PropTypes.bool,
    hasSynopticAgendaButton: PropTypes.bool,
    agendaProps: PropTypes.object,
    hasBackToAgendaButton: PropTypes.bool,
    onSearchClear  : PropTypes.func,
    synchroFavBtnStatus: PropTypes.string,
    rootCategoryButtonInToolbar : PropTypes.bool,
    navigateToRootCategory: PropTypes.func,
    searchResults: PropTypes.array,
    lastResize   : PropTypes.number,
    isDataFiltered: PropTypes.bool,
    theme: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => state["appToolbar"]

export default connect(mapStateToProps)(AppToolbar)
