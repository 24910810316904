
//import { combineReducers } from 'redux'

// Conf
import Pages from 'src/pages/Pages';

import * as Cordova from 'src/core/cordova/cordovaReducerAndMiddleware';

import appToolbarReducer from 'src/components/app-toolbar/appToolbarReducer';

import { STORE_KEY as adSwapStoreKey } from 'src/components/ad-swap/AdSwap';
import adSwapReducer from 'src/components/ad-swap/adSwapReducer';

import filterDialogReducer                       from 'src/components/filter-dialog/filterDialogReducer';
import { COMPONENT_KEY as filterDialogKey }      from 'src/components/filter-dialog/FilterDialog';


// STANDALONE COMPONENTS

import chooseLangDialogReducer                   from 'src/components-standalone/choose-lang-dialog/langDialogReducer';
import { COMPONENT_KEY as chooseLangDialogKey }  from 'src/components-standalone/choose-lang-dialog/ChooseLangDialog';

import dataListDialogReducer                     from 'src/components-standalone/data-list-dialog/dataListDialogReducer';
import { COMPONENT_KEY as dataListDialogKey }    from 'src/components-standalone/data-list-dialog/DataListDialog';

import disclaimerReducer                         from 'src/components-standalone/disclaimer/disclaimerReducer';
import { COMPONENT_KEY as disclaimerKey }        from 'src/components-standalone/disclaimer/Disclaimer';

import fullLoaderReducer                         from 'src/components-standalone/full-loader/fullLoaderReducer';
import { COMPONENT_KEY as fullLoaderKey }        from 'src/components-standalone/full-loader/FullLoader';

import intersticielReducer                       from 'src/components-standalone/intersticiel/intersticielReducer';
import { COMPONENT_KEY as intersticielKey }      from 'src/components-standalone/intersticiel/Intersticiel';

import noteModalReducer                          from 'src/components-standalone/note-modal/noteModalReducer';
import { COMPONENT_KEY as noteModalKey }         from 'src/components-standalone/note-modal/NoteModal';

import notificationsReducer                      from 'src/components-standalone/notifications/notificationsReducer';
import { COMPONENT_KEY as notificationsKey }     from 'src/components-standalone/notifications/Notifications';

import pollDialogReducer                         from 'src/components-standalone/poll-dialog/pollDialogReducer';
import { COMPONENT_KEY as pollDialogKey }        from 'src/components-standalone/poll-dialog/PollDialog';

import searchPlaceDialogReducer                  from 'src/components-standalone/search-place-dialog/searchPlaceDialogReducer';
import { COMPONENT_KEY as searchPlaceDialogKey } from 'src/components-standalone/search-place-dialog/SearchPlaceDialog';

import shareDialogReducer                        from 'src/components-standalone/share-dialog/shareDialogReducer';
import { COMPONENT_KEY as shareDialogKey }       from 'src/components-standalone/share-dialog/ShareDialog';

import genericItemPageReducer from 'src/pages/generic-item-page/genericItemPageReducer';
import { GENERIC_ITEM_PAGE_STORE_KEY } from 'src/pages/generic-item-page/GenericItemPage';

/**
 * Simple generic function to add a reducer
 * @param {object}   reducers (modified by reference)
 * @param {string}   key
 * @param {function} reducer
 */
function addReducer(reducers, key, reducer) {
    if (typeof reducers[key] !== 'undefined') {
        console.error('Overriding reducer ' + key);
    }
    reducers[key] = reducer;
};

export default (state = {}, action) => {
    let reducers = {};

    // Add reducer for each page
    Object.keys(Pages).forEach((pageKey) => {
        if (typeof Pages[pageKey].getReduxReducer === 'function') {
            addReducer(reducers, pageKey, Pages[pageKey].getReduxReducer(state, action));
        }
    });

    addReducer(reducers, 'Cordova', Cordova.reducer(state.Cordova, action));

    [  { key: chooseLangDialogKey,  reducer: chooseLangDialogReducer },
       { key: dataListDialogKey,    reducer: dataListDialogReducer },
       { key: disclaimerKey,        reducer: disclaimerReducer },
       { key: fullLoaderKey,        reducer: fullLoaderReducer },
       { key: intersticielKey,      reducer: intersticielReducer },
       { key: notificationsKey,     reducer: notificationsReducer },
       { key: pollDialogKey,        reducer: pollDialogReducer },
       { key: searchPlaceDialogKey, reducer: searchPlaceDialogReducer },
       { key: shareDialogKey,       reducer: shareDialogReducer },
       { key: filterDialogKey,      reducer: filterDialogReducer },
       { key: adSwapStoreKey,       reducer: adSwapReducer },
       { key: noteModalKey,         reducer: noteModalReducer },
       // { key: 'fetchResult',        reducer: fetchResultReducer }, // FIXME hardcoded string
       { key: 'appToolbar',         reducer: appToolbarReducer }, // FIXME hardcoded string
       { key: GENERIC_ITEM_PAGE_STORE_KEY, reducer: genericItemPageReducer },
    ].forEach(data => {
        addReducer(reducers, data.key, data.reducer(state[data.key], action));
    });

    return reducers;
};
