
import config from 'data/config/config';
import { DATA_TYPE_PARTICIPANTS } from 'data/config/dataConfig';

import fetchHelper, { HEADERS } from 'src/core/util/FetchHelper';
import { WS_ERRORS, getErrorFromHttpStatus } from '../WS_ERRORS';
import STATUSES from '../statuses';
import { transformData } from './helpers';
import { completeData } from 'src/core/query/Query';


const LOG_PREF = '[UserDataWS Taiga] ';


export const parseUserData = userData => (
    completeData(
        transformData.apiToApp.user(userData),
        DATA_TYPE_PARTICIPANTS,
        [ 'participantDbEvents' ]
    )
)

export function getUserData(cb) {
    const url = config.LOGIN.WS_URL+'/me.htm';

    let opts = {
        method: 'GET',
        headers: [ HEADERS.JSON_CONTENT_TYPE ],
        withCredentials: true, // use cookie (the browser takes care of it)
    };
    fetchHelper(
        url,
        opts,
        true, // parse response as json
        function onSuccess(response) {
            switch(response.status) {
                case STATUSES.SUCCESS:
                    cb(
                        null, // error code
                        parseUserData(response.datas), // userdata
                    );
                    break;

                case STATUSES.ERROR:
                    cb(getErrorFromHttpStatus(response.http_code));
                    break;

                default: console.error(LOG_PREF+'Unexpected response status: '+response.status);
            }
        },
        function onFailure(error, message) {
            cb(WS_ERRORS.NETWORK);
        },
        false // showModalOnError
    );
}
if (config.ENV === 'dev') {
    global.getUserDataUsingTaigaWs = getUserData;
}


const USE_FORM_DATA = false;

export function saveUserData(userData, cb) {
    const url = config.LOGIN.WS_URL+'/updateMe.htm';

    let transformedUserData = transformData.appToApi.user(userData);

    let formData;
    if (USE_FORM_DATA) {
        formData = new FormData();
        Object.keys(transformedUserData).forEach(field => {
            formData.append(field, transformedUserData[field]);
        });
    }

    let opts = {
        method: 'POST',
        headers: [ HEADERS.JSON_CONTENT_TYPE ],
        body: USE_FORM_DATA ? formData : JSON.stringify({ updatedFields: transformedUserData }),
        withCredentials: true, // use cookie (the browser takes care of it)
    };
    fetchHelper(
        url,
        opts,
        true, // parse response as json
        function onSuccess(response) {
            if (response.status === 'success') {
                cb(null);
            }
            else {
                cb(getErrorFromHttpStatus(response.http_code));
            }
        },
        function onFailure(error, message) {
            cb(WS_ERRORS.NETWORK);
        },
        false // showModalOnError
    );
}
if (config.ENV === 'dev') {
    global.saveUserDataUsingTaigaWs = saveUserData;
}
