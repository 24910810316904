export default {
  id: 'fr',
  common: {
    appTitle: 'REA 2020',
    colon: ' : ',
    questionMark: ' ?',
    noResult: 'Aucun résultat',
    yes: 'Oui',
    no: 'Non',
    ok: 'OK',
    confirm: 'Valider',
    cancel: 'Annuler',
    close: 'Fermer',
    keep: 'Conserver',
    delete: 'Effacer',
    chooseLang: 'Choix de la langue',
    addToFavorites: 'Ajouter aux favoris',
    addNote: 'Ajouter une note',
    editNote: 'Editer la note',
    defaultPlaceLabel: 'Plan',
    noNetworkTitle: 'Réseau indisponible',
    noNetwork: 'Nous vous prions de réessayer plus tard',
    loading: 'Veuillez patienter...',
    showAll: 'Afficher sur la carte',
    noPlacesToShowOnMap: 'Aucun emplacement à afficher sur la carte',
    termsOfUse: ' ',
    information: 'Informations',
    contact: 'Contact',
    display: 'Affichage',
    switch2Columns: 'Basculer sur un mode d\'affichage adapté à la largeur de votre écran ? (conseillé)',
    share: 'Partager',
    goBack: 'Retour',
    goToHome: 'Revenir à l\'accueil',
    goToRootCategory: 'Revenir à l\'accueil de la rubrique',
    login: 'Identification',
    goToFavorites: 'Voir vos favoris',
    goToInbox: 'Accéder aux messages',
    goToSearch: 'Recherche de contenu',
    goToFilter: 'Filtrer le contenu',
    goToSynopticAgenda: 'Voir le synoptique',
    goBackToAgenda: 'Retour à la liste des événements',
    openMenu: 'Ouvrir le menu',
    relatedContent: 'Contenu lié',
    contributions: 'Contributions',
    feedback: 'Retour',
    rateThisSession: 'Évaluez cette session',
    seeMore: 'voir plus ...',
    seeLess: 'voir moins ...'
  },
  location: {
    yourLocation: 'Votre position'
  },
  disclaimer: {
    text: 'Des cookies sont utilisés sur cette app pour effectuer des statistiques anonymes d’utilisation.'
  },
  share: {
    mail: 'Partager par email',
    facebook: 'Partager via Facebook',
    linkedin: 'Partager via LinkedIn',
    twitter: 'Partager via Twitter',
    positionShare: 'Position partagée',
    // favorite position share description
    shareFail: 'Echec du partage',
    linkedinShareDone: 'Page partagée sur votre profil LinkedIn',
    linkedinShareFail: 'Echec du partage via LinkedIn',
    facebookShareDone: 'Page partagée sur votre profil Facebook',
    facebookShareFail: 'Echec du partage via Facebook'
  },
  data: {
    brands: {
      singular: 'Marque',
      plural: 'Marques',
      title: 'Marques'
    },
    brand_categories: {
      singular: 'Catégorie de marques',
      plural: 'Catégories de marques',
      title: 'Marques'
    },
    exhibitors: {
      singular: 'Exposant',
      plural: 'Exposants',
      title: 'Exposants',
      searchPlaceholder: 'Exposants'
    },
    exhibitor_categories: {
      singular: 'Catégorie d\'exposants',
      plural: 'Catégories d\'exposants',
      title: 'Exposants'
    },
    restaurants: {
      singular: 'Restaurant',
      plural: 'Restaurants',
      title: 'Restaurants'
    },
    restaurant_categories: {
      singular: 'Catégorie de restaurants',
      plural: 'Catégories de restaurants',
      title: 'Restaurants'
    },
    services: {
      singular: 'Service',
      plural: 'Services',
      title: 'Services'
    },
    service_categories: {
      singular: 'Catégorie de services',
      plural: 'Catégories de services',
      title: 'Services'
    },
    aircrafts: {
      singular: 'Aéronef',
      plural: 'Aéronefs',
      title: 'Aéronefs'
    },
    aircraft_categories: {
      singular: 'Catégorie d\'aéronefs',
      plural: 'Catégories d\'aéronefs',
      title: 'Aéronefs'
    },
    events: {
      singular: 'Événement',
      plural: 'Programme',
      title: 'Programme',
      subevents: 'Interventions'
    },
    event_categories: {
      plural: 'Spécialité, Type, Programme',
      title: 'Programme'
    },
    conferences: {
      singular: 'Conférence',
      plural: 'Conférences'
    },
    speakers: {
      singular: 'Intervenant',
      plural: 'Intervenants',
      title: 'Intervenants',
      moderators: 'Modérateurs'
    },
    papers: {
      singular: 'Abstract',
      plural: 'Abstracts',
      title: 'Abstracts'
    },
    paper_categories: {
      singular: 'Abstract',
      plural: 'Abstracts',
      title: 'Abstracts'
    },
    newproducts: {
      singular: 'Produit',
      plural: 'Produits',
      title: 'Produits'
    },
    newproduct_categories: {
      singular: 'Catégorie de produits',
      plural: 'Catégories de produits',
      title: 'Produits'
    },
    animations: {
      plural: 'Animations',
      title: 'Animations',
      singular: 'Animation',
      go: 'Voir sur le plan'
    },
    favoritepositions: {
      plural: 'Positions',
      singular: 'Position'
    },
    flights: {
      singular: 'Vol',
      plural: 'Vols'
    },
    flights_schedule: {
      locale: 'fr',
      // wtf ?
      longDateFormat: 'L HH:mm:ss',
      title: 'Agenda des vols',
      refresh: 'Rafraîchir',
      lastFetched: 'Dernière update',
      unknownAircraft: 'Aéronef inconnu',
      notScheduledYet: 'Horaire à venir',
      errorWhileFetching: 'Une erreur est survenue',
      lastRefreshed: 'Dernière mise à jour : ',
      noResults: 'Les vols ne sont pas encore programmés'
    },
    docunits: {
      singular: 'Casier presse',
      plural: 'Casiers presse'
    }
  },
  dataListDialog: {
    defaultTitle: 'todo default title'
  },
  size: {
    bytes: ' octets',
    kilobytes: ' ko',
    megabytes: ' mo'
  },
  update: {
    modalTitle: 'Mise à jour',
    update_detected: 'Mettre à jour les données ?',
    cancel_update: 'Annuler la mise à jour',
    update_done: 'Mise à jour terminée',
    new_version_detected: 'Nouvelle version détectée',
    timeout: 'Délai dépassé'
  },
  lang: {
    fr: 'français',
    en: 'anglais',
    zh: 'chinois'
  },
  menu: {
    restaurants: 'Restaurants',
    hours: 'Horaires',
    inbox: 'Messages',
    brands: 'Marques',
    exhibitors: 'Exposants',
    exhibitorsAndNp: 'Exposants & produits',
    services: 'Infos pratiques',
    speakers: 'Intervenants',
    map: 'Plan',
    agenda: 'Programme',
    experiences: 'Animations',
    medias: 'Actualités',
    update: 'Mettre à jour',
    language: 'Langue',
    close: 'Fermer le menu',
    pmr: 'PMR',
    toggleProfile: 'Changer de profil',
    aircraftsAndFlights: 'Aéronefs & Vols',
    livewall: 'LiveWall',
    twitter: 'Twitter',
    presskits: 'Casiers presse',
    cgu: 'CGU',
    papers: 'Abstracts',
    socialagenda: 'Programme Social'
  },
  home: {
    title: 'Accueil',
    myVisit: 'Ma visite',
    agenda: 'PROGRAMME',
    experiences: 'ANIMATIONS',
    exhibitors: 'EXPOSANTS',
    exhibitorsAndProducts: 'EXPOSANTS & PRODUITS',
    mapsAndGroups: 'Plan',
    map: 'PLAN',
    aircraftsAndFlights: 'AÉRONEFS & VOLS',
    pmr: 'PMR',
    practicalInfo: 'INFOS PRATIQUES',
    medias: 'ACTUALITÉS',
    papers: 'ABSTRACTS',
    presskits: 'CASIERS PRESSE',
    socialagenda: 'PROGRAMME SOCIAL',
    speakers: 'INTERVENANTS'
  },
  profile: {
    title: 'Choix du profil',
    mainLabel: 'Sélectionnez votre profil',
    codeInput: 'Saisissez le code d\'accès',
    invalidCode: 'Code d\'accès invalide',
    pro: 'Pro',
    gp: 'Grand public',
    presse: 'Presse'
  },
  exhibitor: {
    contacts: 'contacts',
    entities: 'entités',
    agentCountries: 'pays agents',
    brands: 'marques',
    activities: 'activités',
    categories: 'catégories'
  },
  placeSearch: {
    editStart: 'Modifier départ',
    setDestination: 'Etape suivante',
    itinerary: 'Itinéraire',
    start: 'Choisissez votre point de départ',
    destination: 'Destination',
    poi: 'Point d\'intérêt'
  },
  aircraft: {
    pilot: 'Pilote',
    coPilot: 'Co-pilote',
    country: 'Pays',
    manufacturer: 'Constructeur',
    category: 'Catégorie',
    usage: 'Utilisation',
    wingSpan: 'Envergure',
    length: 'Longueur',
    emptyWeight: 'Tonnage',
    maxCruiseSpeed: 'Vitesse max. de croisière',
    characteristics: 'Caractéristiques',
    messageNoFlightYet: 'Cet aéronef est prévu dans un programme en vol. Information à venir ultérieurement'
  },
  newproducts: {
    launchDate: 'Mise sur le marché '
  },
  events: {
    to: 'à',
    access: 'Accès',
    publics: 'Public'
  },
  map: {
    title: 'Plan',
    goTo: 'Y aller',
    shouldReload: 'Les données du plan ont été mises à jour, souhaitez-vous le recharger maintenant ?',
    reloadDueToUpdate: 'Mise à jour du plan…',
    errors: {
      // automatic matching. Refer to MobiGeo API doc to know the error codes
      ROUTE_ERROR_NO_ROUTE_FOUND: 'Aucun itinéraire trouvé.',
      ROUTE_ERROR_USER_HAS_NO_POSITION: 'Vous n\'êtes pas localisé.'
    }
  },
  favorites: {
    title: 'Favoris',
    download: 'Télécharger (excel)',
    yourFavs: 'Vos favoris',
    other: 'Autres'
  },
  papers: {
    title: 'Abstracts'
  },
  agenda: {
    title: 'Programme'
  },
  notes: {
    title: 'Note sur',
    pageTitle: 'Mes notes',
    pageLinkTitle: 'Mes Notes',
    delete: 'Supprimer la note',
    deleteAlert: 'Etes-vous certain de vouloir supprimer cette note ?',
    exportButton: 'Exporter mes notes'
  },
  medias: {
    title: 'Réseaux sociaux',
    label: 'Suivez nos actualités sur'
  },
  pmr: {
    title: 'PMR'
  },
  search: {
    title: 'Recherche',
    placeholderSuffix: '... (min. 2 car.)',
    submitLabel: 'Ok',
    searchFor: 'Rechercher des ' //        keywords: 'Mots clés'

  },
  hours: {
    title: 'Horaires d\'ouverture'
  },
  intersticiel: {
    closeLabel: 'Skip Ad'
  },
  promoMs: '',
  inbox: {
    title: 'Messages',
    empty: 'Vous n\'avez pas de message',
    dismiss: 'Ignorer',
    confirmNotif: 'OK'
  },
  login: {
    title: 'Identifiez-vous',
    fillTheFields: 'Merci de saisir votre identifiant et mot de passe avant de valider',
    badLogin: 'Votre identifiant ou mot de passe est incorrect',
    loginText: 'Identifiant',
    passwordText: 'Mot de passe',
    loginButton: 'Valider',
    loginSuccess: 'Vous êtes connecté',
    loginToSyncYourFavs: 'Entrez les mêmes identifiants créés lors de votre demande de badge pour synchroniser vos favoris entre la version web eurosatory.com et l’application mobile !',
    introductoryText: 'Vos favoris entre vos différentes sessions web et mobile se synchronisent automatiquement.',
    forgottenPassword: 'J\'ai oublié mon mot de passe',
    createAccount: 'Créer un compte',
    tos: 'Conditions d\'utilisation',
    profile: 'Profil',
    logout: 'Déconnexion',
    noNetwork: 'Vous n\'êtes pas connecté à Internet',
    loading: 'Vérification...'
  },
  profileDialog: {
    title: 'Profil',
    fields: {
      title: 'Civilité',
      firstName: 'Prénom',
      lastName: 'Nom',
      organisation: 'Organisation',
      position: 'Rôle',
      email: 'Email',
      phone: 'Téléphone'
    }
  },
  appointment: {
    button: 'Mise en relation',
    setProfileFirst: 'Vous devez renseigner votre profil pour pouvoir utiliser cette fonctionnalité.',
    sent: function sent(formatedDate, email, phone) {
      return "Demande envoy\xE9e ".concat(formatedDate, ". Votre destinataire vous r\xE9pondra par courriel \xE0 l\u2019adresse ").concat(email) + (phone ? " ou par tel au ".concat(phone) : '') + '.';
    },
    errors: {
      GENERIC: 'Il n\'a pas été possible d\'envoyer la demande de mise en relation.',
      INVALID_REQUEST: 'Une erreur est survenue lors de l\'envoi de la demande de mise en relation.',
      EXHIBITOR_NOT_FOUND: 'Une erreur est survenue lors de l\'envoi de la demande de mise en relation.',
      EXHIBITOR_HAS_NO_CONTACT_EMAIL: 'Une erreur est survenue lors de l\'envoi de la demande de mise en relation.',
      ALREADY_SENT: 'Une demande de mise en relation a déjà été envoyée pour cet exposant.'
    }
  },
  synchroFavs: {
    codePlaceholder: 'Entrer un code',
    favoritesPageNoticeUnauthorizedMode: 'Vous pouvez synchroniser vos favoris entre vos différentes sessions web et app mobile. Pour cela cliquez sur',
    favoritesPageNoticeExtraUnauthorizedMode: 'Vos données de favoris ne sont ni exploitées pour quelque fin que ce soit, ni partagées avec un tiers. Vous pouvez arrêter la synchronisation à tout moment en appuyant sur le bouton',
    favoritesPageNotice: 'Quand vous êtes authentifié, vos favoris se synchronisent entre vos sessions web et mobile.',
    logoutConfirm: 'Vos favoris ne sont plus synchronisés sur ce terminal, voulez-vous les conserver en local ou les effacer ?',
    logoutConfirmUnauthorizedMode: 'La synchronisation sur ce terminal va être arrêtée. Voulez-vous effacer ou conserver vos favoris en local ?',
    status: {
      ongoing: 'Synchronisation des favoris en cours',
      failed: 'Vos favoris ne sont pas synchronisés. Vérifiez votre connexion de données',
      success: 'Vos favoris sont synchronisés'
    },
    haveCode: 'J\'ai un code',
    noCode: 'je n\'ai pas de code',
    syncCode: 'Code de synchronisation',
    localSyncCode: {
      singular: 'Code de synchronisation utilisé',
      plural: 'Codes de synchronisation utilisés'
    },
    enterCodeNotice: 'Entrez votre code pour synchroniser vos favoris',
    codeCreatedNotice: ' Le code ci-dessous vous a été attribué. Faites OK pour synchroniser vos favoris sur ce terminal',
    invalidCode: 'Code invalide',
    loadingCode: 'Chargement du code ...',
    comfirmDisableSynchro: 'Voulez vous arrêter la synchronisation de vos favoris ?',
    favoritesPageNoticeCodeSynchronised: 'Synchonisé avec le code',
    onlyDigits: 'Chiffres uniquement'
  },
  poll: {
    listTile: 'Sondages disponibles',
    noPolls: 'Aucun sondage disponible',
    sessionTitle: 'Session rating',
    sessionCodeError: 'CODE INVALIDE',
    fillTheFields: 'Merci d\'entrer votre code',
    code: 'code',
    enterCode: 'Merci d\'entrer le code de session',
    pleaseRate: 'Merci d\'évaluer',
    back: 'Retour',
    next: 'Suivant',
    commentPlaceholder: 'Votre commentaire',
    thank: 'Merci',
    submit: 'Soumettre',
    submitted: 'Déjà soumis',
    done: 'Terminé',
    noNetwork: 'Réseau indisponible. Vous pouvez annuler et soumettre plus tard. Vos réponses ont été sauvegardées.',
    willOpen: 'Ce sondage sera ouvert le',
    willClose: 'Ce sondage sera clos le',
    isClosed: 'Ce sondage est clos',
    requireAuthentication: 'Merci de vous authentifier.',
    started: 'Commencé',
    notStarted: 'Non commencé',
    serverError: 'Erreur Serveur. Vous pouvez annuler et soumettre plus tard. Vos réponses ont été sauvegardées.'
  },
  docunit: {
    documents: 'Documents',
    document: 'Document',
    contacts: 'Contacts',
    presskit: 'Casier presse'
  },
  webPush: {
    needSubscribe: 'Get notifications about important news!',
    successSubscribe: 'You are successfully subscribed!',
    blockSubscribe: 'Click to see how to get notifications',
    alreadySubscribed: 'You are already subscribed'
  }
};