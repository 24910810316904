
// Libs
import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';

// App modules
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Menu from 'src/components/menu/Menu';
import Loader from 'src/components/loader/Loader';
import NoResult from 'src/components/no-result/NoResult';
import ContextualSearch from 'src/components/contextual-search/ContextualSearch';

import * as Notes from 'src/core/notes/Notes';

import * as actions from 'src/store/actions';

import '../common-fiche.scss';


export const GENERIC_ITEM_PAGE_STORE_KEY = 'GenericItemPage';

export const DOM_ID = 'generic-item-page';

const LOG_PREF = '[GenericItemPage] ';


class GenericItemPage extends Component {

    state = {}

    /**
     * Update string visible in browser tab/history/favorites
     */
    setDocumentContext() {
        if (this.props.isActive(this.props.pageKey)) {
            this.props.setDocumentContext(this.props.pageTitle);
        }
    }

    componentDidMount() {
        this.fetchIfNeeded(this.props);
        this.props.actions.fetchFavorites();
    }

    componentWillReceiveProps(nextProps) {
        // this.fetchIfNeeded(nextProps);

        // Auto show POI on map if:
        //  - two columns mode is enabled
        //  - for this page, configuration indicates to automatically show the POI
        //  - item has been fetched
        //  - no item had been fetched yet or `id` is different
        if (this.props.twocolumns === true
                && this.props.autoShowPoi
                && nextProps.item
                && (!this.props.item || nextProps.item.id !== this.props.item.id)) {

            this.props.actions.showOnePoiOnMap({
                id        : nextProps.item.id,
                originalId: nextProps.item.original_id,
                type: this.props.dataType,
            });
        }
    }

    /**
     * Fetch data if :
     *  - data has been updated (shouldFetch: true)
     *  - or id is different
     *  - or if we have no exhibitor data and request is neither pending nor without result
     */
    fetchIfNeeded() {
        this.itemHasChanged = this.currentId !== this.props.id || this.currentDataType !== this.props.dataType;

        // NB: `shouldFetch` is truthy after a data update to force `item` refresh
        if ((this.props.shouldFetch === true || this.itemHasChanged) && !this.props.isPending) {
            // console.log('Fetching because: props.shouldFetch:'+this.props.shouldFetch+' || this.itemHasChanged:'+this.itemHasChanged+' (currentId:'+this.currentId+' vs props.id:'+this.props.id+')');

            this.props.actions.itemBeingFetched(this.props.id, this.props.dataType);
            this.props.actions.fetchItem(this.props.id, this.props.dataType);
        }
        this.currentId = this.props.id;
        this.currentDataType = this.props.dataType;
    }

    componentDidUpdate() {
        this.fetchIfNeeded();

        if (this.itemHasChanged === true) {
            this.itemHasChanged = false;

            // child ref not available on componentDidUpdate
            window.setTimeout(this.resetScroll, 100, this);
        }
        this.setDocumentContext();
    }

    setContentComponent = ref => {
        this.contentComponent = ref;
    }

    resetScroll(_this) {
        let context = _this || this;
        if (context.contentComponent && context.contentComponent.scroller) {
            context.contentComponent.scroller.scrollTo(0);
        }
    }

    renderContent() {
        let { item, itemNotFound, searchResults, contextualSearch, actions, labels } = this.props;

        if (searchResults /*&& contextualSearch*/) {
            return (
                <ContextualSearch
                    ref={this.setContentComponent}
                    items={searchResults}
                    onItemClick={actions.clearSearchResults}
                    favIconDisabled={true}
                    actions={actions}
                    labels={labels}
                />
            )
        }
        if (!item) {
            // No result
            if (itemNotFound === true) {
                return <NoResult labels={labels} />;
            }

            // Pending request
            return <Loader labels={labels} />;
        }

        const hasNote = (Notes.getNote(this.props.id,this.props.dataType) && Notes.getNote(this.props.id,this.props.dataType) !== "")==true

        return (
            <this.props.childComponent
                ref={this.setContentComponent}
                item={item}
                isFavorite={this.props.isFavorite}
                favIconDisabled={this.props.favIconDisabled}
                hasNote={hasNote}
                appointmentRequestStatus={this.props.appointmentRequestStatus}
                isLogoFullWidth={!!this.props.isLogoFullWidth}
                profile={this.props.profile}
                queryString={this.props.queryString}
                pageTitle={this.props.pageTitle}
                actions={actions}
                labels={labels} />
        );
    }

    render() {
        console.log(LOG_PREF+'render');

        // Avoid resetting contextual search field
        if (!this.props.isVisible && !this.props.searchResults) {
            return null;
        }

        return (
            <Menu
                options={{ isOpen: this.props.isMenuOpen }}
                actions={this.props.actions}
                labels={this.props.labels}
                profile={this.props.profile}
                associatedPageKey={this.props.pageKey}
                adConfig={this.props.adConfig}
                twoColumns={this.props.twocolumns}
                isLocationEnabled={this.props.isLocationEnabled}>

                <AppToolbar
                    labels={this.props.labels}
                    isDisplayed={this.props.hasToolbar}
                    actions={this.props.actions}
                    title={this.props.labels.data[this.props.dataType].singular}
                    contextualDataTypes={this.props.hasContextualSearch ? [this.props.dataType] : null}
                    onSearchClear={this.props.actions.clearSearchResults}
                    searchResults={this.props.searchResults}
                    pageKey={this.props.pageKey}
                    profile={this.props.profile}
                    hasBackButton={this.props.backButtonInToolbar}
                    hasHomeButton={this.props.homeButtonInToolbar}
                    hasFavButton={this.props.favButtonInToolbar}
                    hasSearchButton={this.props.searchButtonInToolbar}
                    hasMenuButton={this.props.menuButtonInToolbar}>

                    { this.renderContent(this.props) }

                </AppToolbar>
            </Menu>
        );
    }
};

GenericItemPage.propTypes = {
    id          : PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // not required, see ficheConfig.SINGLE_ITEM_DATATYPES
    item        : PropTypes.object,
    isFavorite  : PropTypes.bool,
    isPending   : PropTypes.bool,
    itemNotFound: PropTypes.bool,
    notes       : PropTypes.object,
    hasContextualSearch: PropTypes.bool,
    appointmentRequestStatus: PropTypes.object.isRequired,
    // Common page props
    autoShowPoi       : PropTypes.bool,
    isMenuOpen        : PropTypes.bool.isRequired,
    profile           : PropTypes.string,
    labels            : PropTypes.object.isRequired,
    actions           : PropTypes.object.isRequired,
    isActive          : PropTypes.func.isRequired,
    queryString       : PropTypes.string.isRequired,
    setDocumentContext: PropTypes.func.isRequired,
    isLogoFullWidth   : PropTypes.bool,
    isLocationEnabled : PropTypes.bool,
    searchResults     : PropTypes.object,
    // toolbar
    hasToolbar           : PropTypes.bool,
    homeButtonInToolbar  : PropTypes.bool,
    backButtonInToolbar  : PropTypes.bool,
    searchButtonInToolbar: PropTypes.bool,
    favButtonInToolbar   : PropTypes.bool,
    menuButtonInToolbar  : PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[GENERIC_ITEM_PAGE_STORE_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GenericItemPage);
