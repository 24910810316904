
import { EXHIBITOR_PAGE_KEY } from 'src/pages/pagesKeys';

import ExhibitorContent from './ExhibitorContent';
import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';

export default {
    key      : EXHIBITOR_PAGE_KEY,
    path     : '/exhibitor',
    elId     : DOM_ID,
    className: 'exhibitor-page',
    component: GenericItemPage,
    childComponent: ExhibitorContent,
    relatedDataToFetch: [ 'country', 'cats', 'acts', 'newproducts', 'places', 'events', 'aircrafts', 'docunits' ],
};
