import React, { Component }     from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-custom';
import { AlertDialog } from 'react-onsenui';
import moment from 'moment';

import * as config from 'data/config/config';

import './DetailContributions.scss';

const DEFAULT_DIALOG_PROPS = {
    isCancelable: true,
    animation   : 'default', // or 'none', no other choice sadly
};

let _containerStyle;
/**
 * The point is to create a new object only if value is different,
 * to avoid useless renders
 * @return {object}
 */
const getContainerStyle = () => {
    let maxHeight = document.documentElement.clientHeight*0.75+'px';

    if (!_containerStyle || _containerStyle.maxHeight !== maxHeight) {
        _containerStyle = {
            maxHeight: maxHeight,
        };
    }
    return _containerStyle;
};

class DetailContributions extends Component {
    state = {
        items: null,
        title: null,
        type: null
    }

    componentDidMount() {
        const { actions } = this.props
        actions.fetchContributionsFeed(true)
    }

    more = (type) => {
        const { contributions, labels } = this.props

        const past = contributions.events.DONE
        const upcoming = contributions.events.UPCOMING
        // const cancelled = contributions.events.CANCELLED
        // const undefinedContributions = contributions.events.UNDEFINED

        switch (type) {
            case 'UPCOMING':
                this.setState({
                    items: upcoming,
                    title: labels.contributions.upcoming,
                    type
                })
            break;
            case 'DONE':
                this.setState({
                    items: past,
                    title: labels.contributions.past,
                    type
                })
            break;
            // case 'CANCELLED':
            //     this.setState({ items: cancelled, title: labels.contributions.cancelled })
            // break;
            // case 'UNDEFINED':
            //     this.setState({ items: undefinedContributions, title: labels.contributions.undefined })
            // break;
        }
    }

    dialogProps = () => Object.assign({}, DEFAULT_DIALOG_PROPS, {
        isOpen  : !!this.state.title,
        onCancel: () => this.setState({ title: null, items: null })
    })

    renderSection = (title, section, items, separator = true) => {
        const { labels } = this.props
        if (items.length === 0) return null

        return (
            <div>
                <div className="contributions-section">
                    <div className="contributions-header">
                        <div className="contributions-section-title">
                            {title}
                        </div>
                        <div
                            onClick={() => this.more(section)}
                            onPress={() => this.more(section)}
                            className="contributions-refresh-btn">
                            <span>{labels.contributions.more}</span>
                            <span className="contributions-refresh-btn-icon fa fa-chevron-right"/>
                        </div>
                    </div>
                    {this.renderItems(items, section)}
                </div>
                {separator &&
                    <div className="contributions-separator"/>
                }
            </div>
        )
    }

    renderItems = (items, section) => {
        const { labels } = this.props
        const sortedItems = _.sortBy(items, ['order'])

        return (
            <div className="contributions-body">
                {sortedItems.map((contribution) => {
                    let displayTime = true
                    if (section === 'UPCOMING') {
                        const now = moment()
                        const contribTime = moment(`${contribution.session_date} ${contribution.session_start}`, 'YYYY/MM/DD HH:mm')
                        if (now.isAfter(contribTime)) displayTime = false
                    }
                    return (
                        <div key={contribution.id} className="contributions-item">
                            <div className="contributions-item-details">
                                <h4>{contribution.title}</h4>
                                <div>{contribution.speaker}</div>
                            </div>
                            {contribution.session_start && section === 'UPCOMING' && displayTime &&
                                <div className="contributions-item-time">
                                    {`${labels.contributions.notBefore} ${contribution.session_start}`}
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        )
    }

    render() {
        const { contributions, labels, actions, activateRefresh } = this.props
        const { items, title, type } = this.state

        if (!contributions) return null

        if (contributions.empty) {
            return (
                <div className="contributions">
                    <div className="contributions-section flex">
                        <div className="contributions-empty">
                            {labels.contributions.empty}
                        </div>
                        <div
                            onClick={() => {
                              actions.fetchContributionsFeed(true)
                            }}
                            className='contributions-refresh-btn'
                        >
                            {labels.contributions.refresh}
                        </div>
                    </div>
                </div>
            )
        }

        const n = config.CONTRIBUTIONS_FEED.NUMBER_OF_DISPLAYED_CONTRIBUTIONS
        const current = contributions.events.CURRENT && contributions.events.CURRENT.length > 0 ? contributions.events.CURRENT[0] : contributions.events.CURRENT
        const past = contributions.events.DONE ? contributions.events.DONE.slice(0, n) : []
        const upcoming = contributions.events.UPCOMING ? contributions.events.UPCOMING.slice(0, n) : []
        // const cancelled = contributions.events.CANCELLED ? contributions.events.CANCELLED.slice(0, n) : []
        // const undefinedContributions = contributions.events.UNDEFINED ? contributions.events.UNDEFINED.slice(0, n) : []
        // const refreshBtnClasses = `contributions-refresh-btn ${!activateRefresh ? 'disable-refresh' : ''}`

        const isEmpty =  (!current || current.length === 0)
            && (!past || past.length === 0)
            && (!upcoming || upcoming.length === 0)

        return (
    		<div className="contributions">
                <div className="contributions-section flex">
                    {!isEmpty &&
                        <div className="contributions-date">
                            <div>{labels.contributions.lastRefreshed}</div>
                            <div>{contributions.date}</div>
                        </div>
                    }
                    {isEmpty &&
                        <div className="contributions-empty">
                            {labels.contributions.empty}
                        </div>
                    }
                    <div
                        onClick={() => {
                          // if (activateRefresh) {
                          //   actions.fetchContributionsFeed(true)
                          // }
                          actions.fetchContributionsFeed(true)
                        }}
                        className='contributions-refresh-btn'
                    >
                        {labels.contributions.refresh}
                    </div>
                </div>
                <div className="contributions-separator"/>

                {current &&
                    <div>
                        <div className="contributions-section">
                            <div>{labels.contributions.ps}</div>
                            <h4>{current.session_title}</h4>
                        </div>
                        <div className="contributions-separator"/>
                    </div>
                }

                {current &&
                    <div>
                        <div className="contributions-section">
                            <h4 className="contributions-current-title">{labels.contributions.current}</h4>
                            <div className="contributions-current-details">
                                <h4>{current.title}</h4>
                                <div>{current.speaker}</div>
                            </div>
                        </div>
                        <div className="contributions-separator"/>
                    </div>
                }

                {this.renderSection(labels.contributions.upcoming, 'UPCOMING', upcoming)}
                {this.renderSection(labels.contributions.past, 'DONE', past, false)}
                {/*this.renderSection(labels.contributions.upcoming, 'CANCELLED', cancelled)*/}

                <AlertDialog id="contributions-dialog" {...this.dialogProps()}>
                    <span
                        className="close-contributions-dialog fa fa-times"
                        onClick={()=> {
                            this.setState({
                                items: null,
                                title: null,
                                type: null
                            })
                        }}
                    />
                    <div
                      className="contributions-section generic-modal-content contributions-modal"
                      style={getContainerStyle()}
                    >
                        <h4 className="contributions-modal-title">{title}</h4>
                        {this.renderItems(items, type)}
                    </div>
                </AlertDialog>
            </div>
        )
    }
};

DetailContributions.propTypes = {
	contributions  : PropTypes.object.isRequired,
    labels    : PropTypes.object.isRequired,
    actions   : PropTypes.object.isRequired,
};

export default DetailContributions;
