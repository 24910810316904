module.exports = require('deepmerge')(require('./config.default.js'), {
  native: {
    name: {
      default: 'REA_D'
    },
    id: 'com.mobilespot.srlf.dev',
    // iOS
    appleTeamId: '2BAP3P29V2',
    //      iosAppStoreUrl: 'https://itunes.apple.com/app/paris-air-show/id1484463502',
    iosAppStoreUrl: 'https://itunes.apple.com/app/id1484463502',
    // Android
    // 'androidapp-id': 'TODO', // unused?
    playStoreUrl: ''
  },
  web: {
    url: 'rea-dev.mobile-spot.com'
  }
});