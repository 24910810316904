
import config from 'data/config/config';
import { get as getSearchConfig } from 'data/config/searchConfig';

import { DATA_TYPE_EVENTS, DATA_TYPE_PARTICIPANTS } from 'data/config/dataConfig';

import { AUTOSHOW_POI_ON_MAP } from 'data/config/pagesTwoColumnsConfig';

import {
    ACTIVATE_CONTRIBUTIONS_REFRESH,
    ALL_FAVORITES_DELETED,
    APPOINTMENT_REQUEST_SEND_RESULT,
    APPOINTMENT_REQUEST_SENT,
    CLEAR_SEARCH_RESULTS,
    CONFIG_JSON_LOADED,
    CONTRIBUTIONS_FEED_LOADED,
    DATA_ASSETS_UPDATED,
    FETCH_FAVORITES,
    HAS_NAVIGATED,
    ITEM_BEING_FETCHED,
    ITEM_FETCHED,
    LANG_CHANGED,
    NAVIGATE,
    NOTE_DELETED,
    NOTE_SAVED,
    POLL_CONFIG_LOADED,
    PROFILE_CHANGED,
    SEARCH_PERFORMED,
    TOGGLE_FAVORITE,
    TOGGLE_LOCATION_STATUS,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
    USER_DATA_UPDATED,
} from 'src/store/actionTypes';

import {
    clearSearchResults,
    configJsonLoaded,
    dataUpdated,
    fetchFavorites,
    getPageCommonDefaultState,
    itemFetched,
    langChanged,
    noteDeleted,
    noteSaved,
    pollConfigLoaded,
    profileChanged,
    searchPerformed,
    setIsFavoriteFalse,
    toggleLocationStatus,
    toggleMenu,
    togglePageAfterNavigation,
    updateObject,
    updatePageState,
} from 'src/store/reducers/commons';

import Pages from 'src/pages/Pages';
import { getDatatypeFromPage } from 'src/pages/dataToPageMapping';

import { getRequestStatus } from 'src/core/appointment-request/AppointmentRequestService';
import { persistence as contributionsPersistence } from 'src/core/contributions-feed/ContributionsFeedService';
import { get } from 'src/core/query/Query';
import { isFavorite } from 'src/core/favorites/Favorites';
import GenericItemPage from './GenericItemPage';


const getDefaultState = () => Object.assign({}, getPageCommonDefaultState(), {
    appointmentRequestStatus: {
        isEnabled: config.APPOINTMENT_REQUEST.FEATURE_ENABLED === true,
    },
});


/**
 *
 * @param  {object} state
 * @param  {object} action
 * @param  {string} contextualDataType
 * @return {object}
 */
const _toggleFavorite = (state, action, contextualDataType) => {
    let update = {}
    if (action.favListUpdated
            && action.dataType === contextualDataType
            && typeof state.id !== 'undefined'
            && state.id !== null
            && state.id == action.id) {

        update = {
            isFavorite: isFavorite(state.id, contextualDataType)
        }
    }

    return updateObject(state, {
        favorites: action.favorites,
        ...update
    });
};


function _itemFetched(state, action) {
    let newState = itemFetched(state, action, currentDataType);

    if (newState !== state) {
        // State updated

        if (newState.appointmentRequestStatus.isEnabled) {
            // Check if an appointment request has already been sent
            let originalId = action.item.original_id;
            let appointmentRequestStatus = getRequestStatus(originalId, currentDataType);

            return __updateAppointmentRequestStatus(newState, appointmentRequestStatus);
        }
    }

    if (action.dataType === DATA_TYPE_EVENTS) {
        newState = _getEventContrib(newState);
    }
    if (action.dataType === DATA_TYPE_PARTICIPANTS) {
        newState.contactDate = action.contactDate;
        newState.contactDateLabel = action.contactDateLabel;
        newState.isSameUSer = action.isSameUSer;
    }

    return newState;
}

function _itemBeingFetched(state, action) {
    if (action.id === state.id && action.dataType === state.dataType) {
        return updateObject(state, {
            shouldFetch: false,
            isPending: true,
            item: null,
        });
    }
    return state;
}

/**
 * Request to the backend has been sent
 */
function _appointmentRequestSent(state, action) {
    if (action.dataType === currentDataType && action.dataId === state.id) {
        return __updateAppointmentRequestStatus(state, null, true);
    }
    return state;
}

/**
 * Request to the backend is over
 */
function _appointmentRequestSendResult(state, action) {
    if (action.dataType === currentDataType && action.dataId === state.id) {
        return __updateAppointmentRequestStatus(state, action.status);
    }
    return state;
}

/**
 * Common function to update `appointmentRequestStatus`
 * @param  {object}  state
 * @param  {object}  status
 * @param  {boolean} ongoing
 */
function __updateAppointmentRequestStatus(state, status, ongoing=false) {
    let newState = Object.assign({}, state);
    newState.appointmentRequestStatus = Object.assign({}, state.appointmentRequestStatus)

    if (status && status.date) {
        // Request success
        newState.appointmentRequestStatus.date = status.date;
        newState.appointmentRequestStatus.userEmail = status.userEmail;
        newState.appointmentRequestStatus.userPhone = status.userPhone;
    } else {
        // Ongoing or request failed
        newState.appointmentRequestStatus.date = null;
        newState.appointmentRequestStatus.userEmail = null;
        newState.appointmentRequestStatus.userPhone = null;
    }
    newState.appointmentRequestStatus.ongoing = ongoing;

    return newState;
}


let currentPageKey, currentDataType;

function _updatePageState(state, action) {
    if (isAGenericItemPage(action.pageKey)) {
        currentPageKey = action.pageKey;
        currentDataType = getDatatypeFromPage(currentPageKey);

        return updateObject(state, {
            childComponent: Pages[currentPageKey].childComponent,
            dataType: currentDataType,
            shouldFetch: true,
            isPending: false,
            itemNotFound: false,
            autoShowPoi: AUTOSHOW_POI_ON_MAP[currentPageKey] === true,
            hasContextualSearch: !!getSearchConfig(state.profile)[currentDataType],
            ...action.props
        });
    }
    return state;
}

function _navigate(state, action) {
    if (isAGenericItemPage(action.pageKey)) {
        if (action.pageKey !== currentPageKey || action.options.id !== state.id) {
            return updateObject(state, {
                item: null,
                shouldFetch: false,
                isPending: true,
            });
        }
    }
    return state;
}

const isAGenericItemPage = pageKey => Pages[pageKey].component === GenericItemPage


const _getEventContrib = (state) => {
    const contributions = contributionsPersistence.get();

    if (state.item && state.item.lump && state.item.lump.rt_code) {

        if (contributions
            && contributions.value
            && contributions.value.sc_code) {

            const rt_code = state.item.lump.rt_code
            const eventContrib = contributions.value.sc_code[rt_code]
            const contribDate = contributions.value.current_time

            if (eventContrib) {
                return updateObject(state, {
                    contributions: {
                        date: contribDate,
                        events: eventContrib
                    }
                });
            }
        }

        return updateObject(state, { contributions: { empty: true } })
    }

    return updateObject(state, { contributions: null })
}


function _userDataUpdated(state, action) {
    if (!state.item) {
        return state;
    }
    // This works, but fetching the whole item triggers a visible 'loading' sequence
    // return updateObject(state, { shouldFetch: true });

    let updatedItem = get(state.id, state.dataType, Pages[currentPageKey].relatedDataToFetch);

    // Alternative solution:
    // Directly update the item
    //  - more code to maintain
    //  + better user experience
    return updateObject(state, {
        item: updatedItem,
    });
}


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case ACTIVATE_CONTRIBUTIONS_REFRESH: return updateObject(state, { activateRefresh: action.activate });

        case ALL_FAVORITES_DELETED: return setIsFavoriteFalse(state);

        case APPOINTMENT_REQUEST_SEND_RESULT: return _appointmentRequestSendResult(state, action);

        case APPOINTMENT_REQUEST_SENT: return _appointmentRequestSent(state, action);

        case CLEAR_SEARCH_RESULTS : return clearSearchResults(state);

        case CONFIG_JSON_LOADED: return configJsonLoaded(state);

        case CONTRIBUTIONS_FEED_LOADED: return _getEventContrib(state, action.payload);

        case DATA_ASSETS_UPDATED: return updateObject(state, { shouldFetch: true });

        case FETCH_FAVORITES: return fetchFavorites(state, action);

        case HAS_NAVIGATED: return togglePageAfterNavigation(state, currentPageKey, currentPageKey);

        case ITEM_BEING_FETCHED: return _itemBeingFetched(state, action);

        case ITEM_FETCHED: return _itemFetched(state, action);

        case LANG_CHANGED: return langChanged(state, action);

        case NAVIGATE: return _navigate(state, action);

        case NOTE_DELETED: return noteDeleted(state, action);

        case NOTE_SAVED: return noteSaved(state, action);

        case POLL_CONFIG_LOADED : return pollConfigLoaded(state, action);

        case PROFILE_CHANGED: return profileChanged(state, action, currentPageKey);

        case SEARCH_PERFORMED : return searchPerformed(state, action, currentPageKey);

        case TOGGLE_FAVORITE: return _toggleFavorite(state, action, currentDataType);

        case TOGGLE_LOCATION_STATUS: return toggleLocationStatus(state, action);

        case TOGGLE_MENU: return toggleMenu(state, action, currentPageKey);

        case UPDATE_PAGE_STATE: return _updatePageState(state, action);

        case USER_DATA_UPDATED: return _userDataUpdated(state, action);

        default: return state;
    }
};
