
import React, { PureComponent } from 'react';
import PropTypes    from 'prop-types';
import Measure      from 'react-measure';
import { debounce } from 'lodash-custom';

import { isIOS, isWkWebview } from 'src/core/util/browser';

import './UseRemainingHeight.scss';


const LOG_PREF = '[RemainingHeight] ';

class UseRemainingHeight extends PureComponent {

    state = {
        contentStyle: {}
    }

    onContentDimensionUpdate = (measure) => {
        if (measure && measure.bounds) {
            this.setState({
                contentStyle: Object.assign({}, this.state.contentStyle, {
                    height: document.documentElement.clientHeight - measure.bounds.top - 1
                                + (isWkWebview() ? 20 : 0)
                                //+ (global.isCordovaContext && isIphoneX() /*&& getIosVersion() < 13*/ ? 58 : 0),
                })
            });
        }
    }


    scrollTo = debounce(value => {
        if (this.containerEl) {
            // if isIOS() the use `src/core/util/scrollHack` instead ?

            this.containerEl.scrollTop = value;
        }
    }, 50);

    setContainerElement = el => {
        this.containerEl = el;
    }

    render() {
        return (
            <Measure bounds onResize={this.onContentDimensionUpdate}>
                {({ measureRef }) => (
                    <div
                        className="remaining-height-container"
                        style={this.state.contentStyle}
                        ref={measureRef}>

                            { this.props.children }
                    </div>
                )}
            </Measure>
        );
    }

}

UseRemainingHeight.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
};

export default UseRemainingHeight;
