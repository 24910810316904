
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as actions from 'src/store/actions';

import './ChooseLangDialog.scss';


export const COMPONENT_KEY = 'ChooseLangDialog';
export const CONTAINER_DOM_ID = 'choose-lang-dialog-container';


const DEFAULT_DIALOG_PROPS = {
    // TODO: Export that to data/config/dialogsConfig ?
};

/**
 * Display a dialog (modal) allowing user to change current language for the whole app
 * @param  {object} props
 */
const ChooseLangDialog = props => {

    let currentLangId = props.labels.id;

    /**
     * Actions when user has clicked a flag
     * @param  {string} lang
     */
    function handleFlagSelected(lang) {
        if (lang !== currentLangId) {
            props.actions.setLanguage(lang);
        }
        props.actions.hideChooseLangDialog();
    }

    /**
     * Return an array of button-like flags for every supported language
     * @return {array}
     */
    const getLangEls = () => (
        props.supportedLangs.map(lang => {
            let cssClasses = ['flag-select', 'flag-'+lang];

            if (lang === currentLangId) {
                cssClasses.push('is-current-lang');
            }
            return (
                <span key={ lang }
                      title={ props.labels.lang[lang] }
                      onClick={() => { handleFlagSelected(lang); }}
                      className={ cssClasses.join(' ') }></span>
            );
        })
    );

    let dialogProps = Object.assign({}, DEFAULT_DIALOG_PROPS, {
        open   : props.isOpen,
        onClose: props.actions.hideChooseLangDialog, // important, to update `open` value in all cases
    });

    return (
        <Dialog {...dialogProps}>
            <DialogTitle className="title-font modal-title">{props.labels.common.chooseLang}</DialogTitle>
            <div className="generic-modal-content">
                <div id="cl-langs">{ getLangEls() }</div>

                <div className="generic-btn-container">
                    <div className="generic-btn-cancel"
                         onClick={props.actions.hideChooseLangDialog}>{props.labels.common.cancel}</div>
                </div>
            </div>
        </Dialog>
    );
};

ChooseLangDialog.propTypes = {
    isOpen     : PropTypes.bool.isRequired,
    labels     : PropTypes.object.isRequired,
    actions    : PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChooseLangDialog);
