
// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-custom';
import { iosHackScrollTo0 } from 'src/core/util/browser';

import './SearchField.scss';


const LOG_PREF = '[SearchField] ';


class SearchField extends React.PureComponent {

    value = ''
    inputIsVoid = true
    inputEl = null
    state = {}

    UNSAFE_componentWillUpdate(nextProps) {
        if (this.props.searchResults && !nextProps.searchResults) {
            this.clear()
        }
    }

    getClearIconStyle = () => ({
        opacity: this.inputIsVoid ? .3 : 1,
    })

    onInputChange = e => {
        this.setValue(e.target.value);
    }

    /**
     * Handle search value change
     * @param {string} newValue
     */
    setValue(newValue) {
        /*if (this.value === newValue) {
            return;
        }*/
        this.value = newValue;

        // Empty input
        if (!this.value || this.value.length === 0) {
            this.inputIsVoid = true;
            this.debouncedPerform.cancel();
            this.props.clearResults();
            return;
        }
        // Non-empty input
        else {
            this.inputIsVoid = false;
            if (this.value.length > this.props.minChars-1) {
                this.debouncedPerform(this.value);
            }
        }
    }

    // Spare CPU
    debouncedPerform = debounce(str => {
        this.props.perform(str);
    }, 500);

    clear = () => {
        // Should be set
        if (this.inputEl) {
            this.inputEl.value = '';
            this.setValue('');
            this.focus();
        }
    }

    focus = () => {
        if (this.inputEl) {
            this.inputEl.focus();
        }
    }

    componentDidMount() {
        this.focus();
    }

    // componentDidUpdate() {
        // this.focus();
    // }

    setInputElement = el => {
        this.inputEl = el;
    }

    onBlur = e => {
        iosHackScrollTo0();

        if (typeof this.props.onBlur === 'function') {
            this.props.onBlur(e);
        }
    }

    render() {
        console.log(LOG_PREF+'render');
        return (
            <div className="search-field-container">
                <div className="search-field-input-container">
                    <input
                        ref={ this.setInputElement }
                        onChange={ this.onInputChange }
                        onBlur={ this.onBlur }
                        placeholder={ this.props.placeholder } />
                </div>
                <div className="search-field-clear-container">
                    <span
                        className="search-field-clear fa fa-times"
                        style={ this.getClearIconStyle() }
                        onClick={ this.clear } />
                </div>
            </div>
        );
    }
};

SearchField.propTypes = {
    placeholder : PropTypes.string.isRequired,
    perform     : PropTypes.func.isRequired,
    clearResults: PropTypes.func.isRequired,
    minChars    : PropTypes.number.isRequired,
    onBlur      : PropTypes.func,
};

export default SearchField;
