
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { EVENT_PAGE_SPEAKERS_AS_ACCORDION } from 'data/config/ficheConfig';
import { DATA_TYPE_EVENTS, DATA_TYPE_PAPERS, DATA_TYPE_SPEAKERS } from 'data/config/dataConfig';
import { EVENT_PAGE_KEY } from 'src/pages/pagesKeys';

import Description from 'src/components/fiche/Description';
import DetailPlaces from 'src/components/detail-places/DetailPlaces';
import DetailSpeakers from 'src/components/detail-speakers/DetailSpeakers';
//import DetailPapers from 'src/components/detail-papers/DetailPapers';
import DetailDataList from 'src/components/detail-data-list/DetailDataList';
import DetailExhibitor from 'src/components/detail-exhibitor/DetailExhibitor';
import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import ShareButton from 'src/components/share-button/ShareButton';
import UseRemainingHeight from 'src/components/use-remaining-height/UseRemainingHeight';
import LinkButton from 'src/components/link-button/LinkButton';
import DetailDocument from 'src/components/detail-document/DetailDocument';
import DetailContributions from 'src/components/detail-contributions/DetailContributions';
import WebsiteRow from 'src/components/fiche/WebsiteRow';
import AdSimple from 'src/components/ad-simple/AdSimple';
import List from 'src/components/list/List';
import NoteButton from 'src/components/notes/NoteButton';
import { formatLongDateTime } from 'src/core/Lang';

import '../common-fiche.scss';
import './EventPage.scss';

class EventContent extends PureComponent {

    state = {}

    setScroller = ref => {
        // Used by parent component
        this.scroller = ref;
    }

    onClickOnFavIcon = () => {
        this.props.actions.toggleFavorite(
            this.props.item.id,
            DATA_TYPE_EVENTS,
            this.props.isFavorite
        );
    }

    componentDidMount() {
        this.setState({ onSpeakerToggle: this.scroller.refreshIScroll });
    }

    componentDidUpdate() {
        this.setState({ onSpeakerToggle: this.scroller.refreshIScroll });
    }

    showPollDialog = () => {
        this.props.actions.showPollDialog()
    }

    renderSpeakers = ({ items, label }) => (
        !Array.isArray(items) || items.length === 0
            ? null

            : EVENT_PAGE_SPEAKERS_AS_ACCORDION

                ? <DetailSpeakers
                      speakers={ items }
                      label={ label }
                      actions={ this.props.actions }
                      labels={ this.props.labels }
                      pageKey={ EVENT_PAGE_KEY }
                      onToggle={ this.state.onSpeakerToggle } />

                : <div>
                    <div className="fiche-separator">{label}</div>
                    <List
                        items={ items }
                        dataType={ DATA_TYPE_SPEAKERS }
                        actions={ this.props.actions }
                        labels={ this.props.labels }
                        isFastAndUgly={ false }
                        displayFavorites={ false }
                        pageKey={ EVENT_PAGE_KEY } />
                  </div>
    )

    /**
    * Generate page content
    */
    render() {
        let lump = this.props.item.lump;
        const preTitle = this.props.item.references.eventType ? (this.props.item.references.eventType.title+(this.props.item.title?" - ":"")) : "";
        const title = preTitle+this.props.item.title;

        const description = this.props.item.description,
            logoUrl = this.props.item.logo,
            website = this.props.item.website,
            linkButton = lump.button,
            poll = lump.poll,
            // poll = true,
            contributions = this.props.contributions;

        return (
            <div className="fiche event content-font"
                 style={{ height: this.props.height }}>

                {/* title */}
                <DetailFixedTitle
                    title={ title }
                    isFav={ this.props.isFavorite }
                    onFavClick={ this.onClickOnFavIcon }
                    labels={ this.props.labels }>

                    <NoteButton
                        itemTitle={this.props.item.title}
                        itemId={this.props.item.id}
                        dataType={DATA_TYPE_EVENTS}
                        hasNote={this.props.hasNote}
                        labels={this.props.labels}
                        actions={this.props.actions} />

                    <ShareButton
                        name={ this.props.pageTitle }
                        queryString={ this.props.queryString }
                        description={ description }
                        image={ logoUrl }
                        labels={ this.props.labels }
                        actions={ this.props.actions }
                        profile={ this.props.profile }
                        pageKey={ EVENT_PAGE_KEY } />

                </DetailFixedTitle>

                <UseRemainingHeight ref={ this.setScroller }>
                    <div className="content">
                        <div className="all-informations content-font">

                            <Description
                               logoFileName={ logoUrl }
                               description={ description }
                               isLogoFullWidth={ this.props.isLogoFullWidth } />

                            <AdSimple
                                actions={ this.props.actions }
                                ad={ lump.ad } />

                            <div>
                                {/* date */}
                                { this.props.item.start_date &&
                                    <div className="fiche-related-data">
                                        <div className="prop-img">
                                            <div className="prop-left">
                                                <div className="icon-font">
                                                    <span className="link">b</span>
                                                </div>
                                            </div>
                                            <div className="prop-right evenements">
                                                <div>
                                                    <span className="start-date">{ formatLongDateTime(this.props.item.start_date, this.props.labels.id) }</span>
                                                    <span className="start-time">
                                                        <span>{ this.props.item.start_time }</span>
                                                        { this.props.item.end_time &&
                                                            <span className="end-time"><span className="end-time-to">{ this.props.labels.events.to }</span>{ this.props.item.end_time }</span>
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/* places */}
                                <DetailPlaces
                                    labels={ this.props.labels }
                                    actions={ this.props.actions }
                                    id={ this.props.item.id }
                                    originalId={ this.props.item.original_id }
                                    entityType={ DATA_TYPE_EVENTS }
                                    places={ this.props.item.references.places }
                                    defaultLabel={ this.props.item.references.defaultEventPlaceLabel } />

                            </div>

                            {/* moderators */}
                            { this.renderSpeakers({
                                items: this.props.item.references.moderators,
                                label: this.props.labels.data.speakers.moderators,
                            }) }

                            {/* simple speakers */}
                            { this.renderSpeakers({
                                items: this.props.item.references.speakers,
                                label: this.props.labels.data.speakers.title,
                            }) }

                            {/* events */}
                            { Array.isArray(this.props.item.references.events) && this.props.item.references.events.length > 0 &&
                                <div>
                                    <div className="fiche-separator">{ this.props.labels.data.events.subevents }</div>
                                    <List
                                        items={ this.props.item.references.events }
                                        dataType={ DATA_TYPE_EVENTS }
                                        actions={ this.props.actions }
                                        labels={ this.props.labels }
                                        isFastAndUgly={ false }
                                        displayFavorites={ false }
                                        pageKey={ EVENT_PAGE_KEY } />
                                </div>
                            }

                            {/* document */}
                            { lump.document &&
                                <DetailDocument
                                    labels={ this.props.labels }
                                    actions={ this.props.actions }
                                    document={ lump.document } /> }

                            {/* papers/abstracts */}
                            <DetailDataList
                                dataType={ DATA_TYPE_PAPERS }
                                items={ this.props.item.references.papers }
                                actions={ this.props.actions }
                                labels={ this.props.labels }
                                pageKey={ EVENT_PAGE_KEY } />

                            {/* information bar */}
                            { (this.props.item.references.exhibitor || lump.access || lump.public) &&
                                <div className="fiche-separator">{ this.props.labels.common.information }</div>
                            }

                            {/* exhibitor */}
                            <DetailExhibitor
                                exhibitor={ this.props.item.references.exhibitor }
                                actions={ this.props.actions }
                                labels={ this.props.labels } />

                            {/* access */}
                            { lump.access &&
                                <div className="free-row">
                                    <div className="prop-img">
                                        <div className="prop-left label">
                                            <div className="color-grey-dark">
                                                <span>{ this.props.labels.events.access }</span>
                                            </div>
                                        </div>
                                        <div className="prop-right  name-label">
                                            <div><span>{ lump.access }</span></div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* audience */}
                            { lump.public &&
                                <div className="free-row">
                                    <div className="prop-img">
                                        <div className="prop-left label">
                                            <div className="color-grey-dark"><span>{ this.props.labels.events.publics }</span></div>
                                        </div>
                                        <div className="prop-right  name-label">
                                            <div><span>{ lump.public }</span></div>
                                        </div>
                                    </div>
                                </div>
                            }

                            { website &&
                                <WebsiteRow
                                    website={ this.props.item.website }
                                    actions={ this.props.actions } />
                            }

                            { linkButton &&
                                <div>
                                    <div className="fiche-separator">{ this.props.labels.common.relatedContent }</div>
                                    <LinkButton
                                        config={ linkButton }
                                        actions={ this.props.actions } />
                                </div>
                            }

                            { poll &&
                                <div>
                                    <div className="fiche-separator">{ this.props.labels.common.feedback }</div>
                                    <div className="poll-btn-container">
                                        <div className='poll-btn' onClick={this.showPollDialog}>
                                            <span className='poll-btn-label'>{ this.props.labels.common.rateThisSession }</span>
                                            <span className="poll-btn-icon fa fa-chevron-right" />
                                        </div>
                                    </div>
                                </div>
                            }

                            { contributions &&
                                <div>
                                    <div className="fiche-separator">{ this.props.labels.common.contributions }</div>
                                    <DetailContributions
                                        contributions={ contributions }
                                        activateRefresh={ this.props.activateRefresh }
                                        actions={ this.props.actions }
                                        labels={ this.props.labels } />
                                </div>
                            }
                        </div>
                    </div>
                </UseRemainingHeight>
            </div>
        );
    }

}

EventContent.propTypes = {
  isFavorite: PropTypes.bool,
  item: PropTypes.object,
  isLogoFullWidth: PropTypes.bool,
  // Common
  queryString: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  labels: PropTypes.object,
  actions: PropTypes.object,
  contributions: PropTypes.object,
  height: PropTypes.number, // prop set from AppToolbar
};

export default EventContent;
