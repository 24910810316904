
// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { debounce } from 'lodash-custom';

// Conf
import {
    NON_CATEGORIES_DATA_TYPE,
    CATEGORIES_DATA_TYPE,
    DATA_TYPE_AIRCRAFT_CATEGORIES,
    DATA_TYPE_EVENT_CATEGORIES,
} from 'data/config/dataConfig';

import { AGENDA_AS_PDF } from 'data/config/config';
import { LIST_PAGE_KEY } from 'src/pages/pagesKeys';

// App modules
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Menu from 'src/components/menu/Menu';
import ContextualSearch from 'src/components/contextual-search/ContextualSearch';
import { getCategoryDatatype } from 'src/core/data-and-assets/Db';
import FilterDialog from 'src/components/filter-dialog/FilterDialog';
import ListPageContent from './ListPageContent';

import * as actions from 'src/store/actions';

import './ListPage.scss';



const LOG_PREF = '[ListPage] ';


class ListPage extends Component {

    pageKey = LIST_PAGE_KEY

    toolbar = React.createRef()


    /**
     * Update string visible in browser tab/history/bookmarks
     */
    setDocumentContext() {
        if (this.props.isActive(this.pageKey)) {
            this.props.setDocumentContext(this.getPageTitle());
        }
    }

    /**
     * String displayed in app toolbar
     * @return {string}
     */
    getPageTitle() {
        return this.props.contextualTitle ||
                    Array.isArray(this.props.inputs)
                        ? this.props.inputs.map(input => this.props.labels.data[input.dataType].title).join(' & ')
                        : '';
    }

    componentDidMount() {
        this.fetchIfNeeded(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.fetchIfNeeded(nextProps);
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.inputs) !== JSON.stringify(this.props.inputs)) {
            if(this.toolbar && this.toolbar.current && this.toolbar.current.hideSearchField)
                this.toolbar.current.hideSearchField();
        }

        this.setDocumentContext();
    }

    /**
     * Fetch data if :
     *  - data has been updated (shouldFetch: true)
     *  - inputs has changed (type/ids couples)
     *  - or if we have no data (!== data empty) and request is neither pending nor without result
     */
    fetchIfNeeded(props) {
        if (props.shouldFetch === true ||
            JSON.stringify(this.props.inputs) !== JSON.stringify(props.inputs) ||
            typeof props.items === 'undefined') {

            this.fetchLists(props.inputs);
        }
    }

    fetchLists = debounce(inputs => {
        this.props.actions.fetchLists(inputs || this.props.inputs);
    }, 25)

    hasNonCategoryDataType() {
        let dataTypes = this.props.inputs.map(input => input.dataType);
        return dataTypes.filter(dataType => CATEGORIES_DATA_TYPE.indexOf(dataType) === -1).length > 0;
    }

    navigateToRootCategory = () => {
        this.props.actions.navigate(LIST_PAGE_KEY, {
            inputs: [{ dataType: getCategoryDatatype(this.props.inputs[0].dataType) }]
        });
    }

    renderContent() {
        if (this.props.searchResults) {
            return (
                <ContextualSearch
                    items={this.props.searchResults}
                    favorites={this.props.favorites}
                    height={this.props.height}
                    labels={this.props.labels}
                    actions={this.props.actions}
                    userData={this.props.userData}
                />
            )
        }

        let hasItems = this.props.items !== null && typeof this.props.items === 'object',
            dataTypes = hasItems ? Object.keys(this.props.items) : [];

        const hasDisplayPdfButton =
            dataTypes.length
            && dataTypes[0] === DATA_TYPE_EVENT_CATEGORIES
            && AGENDA_AS_PDF
            && AGENDA_AS_PDF.FEATURE_ENABLED
            && AGENDA_AS_PDF.URL
            && (!AGENDA_AS_PDF.WEB_ONLY || (AGENDA_AS_PDF.WEB_ONLY && !global.isCordovaContext));

        return (
            <ListPageContent
                className={ ['list-content-container', 'content-font', ...dataTypes].join(' ') }
                isOnGeneralAircraftList={ isOnGeneralAircraftList(this.props) }
                isOnProgrammeRootPage={ isOnProgrammeRootPage(this.props) }
                hasShowOnMapButton={ this.props.hasShowOnMapButton }
                hasGoToSynopticButton={ this.props.hasGoToSynopticButton }
                customStateOnMap={ this.props.customStateOnMap }
                hasDisplayPdfButton={ hasDisplayPdfButton }
                agendaAsPdfUrl={ hasDisplayPdfButton ? AGENDA_AS_PDF.URL : null }
                inputs={ this.props.inputs }
                items={ this.props.items }
                adConfig={ this.props.adConfig }
                header={ this.props.header }
                favorites={ this.props.favorites }
                status={ this.props.status }
                favIconDisabled={ this.props.favIconDisabled }
                isPageVisible={this.props.isVisible}
                userData={this.props.userData}
                actions={ this.props.actions }
                labels={ this.props.labels } />
        );
    }

    render() {
        console.log(LOG_PREF+'render');

        // Avoid resetting contextual search field
        /*if (!this.props.isVisible && !this.props.searchResults) {
            return null;
        }*/

        if (this.props.isFilterVisible) {
            return <FilterDialog />;
        }

        return (
            <Menu
                options={{ isOpen: this.props.isMenuOpen }}
                actions={this.props.actions}
                labels={this.props.labels}
                profile={this.props.profile}
                associatedPageKey={this.pageKey}
                adConfig={this.props.adConfig}
                twoColumns={this.props.twocolumns}
                isLocationEnabled={this.props.isLocationEnabled}>

                <AppToolbar
                    ref={this.toolbar}
                    labels={this.props.labels}
                    isDisplayed={this.props.hasToolbar}
                    actions={this.props.actions}
                    title={this.getPageTitle()}
                    contextualTitle={this.props.contextualTitle}
                    contextualDataTypes={(this.props.inputs || []).map(input => input.dataType)}
                    onSearchClear={this.props.actions.clearSearchResults}
                    searchResults={this.props.searchResults}
                    pageKey={this.pageKey}
                    profile={this.props.profile}
                    hasBackButton={this.props.backButtonInToolbar}
                    hasHomeButton={this.props.homeButtonInToolbar}
                    hasFavButton={this.props.favButtonInToolbar}
                    hasMenuButton={this.props.menuButtonInToolbar}
                    hasSearchButton={this.props.searchButtonInToolbar}
                    hasFilterButton={this.props.hasFilter}
                    rootCategoryButtonInToolbar={this.props.rootCategoryButtonInToolbar}
                    navigateToRootCategory={this.navigateToRootCategory}
                    isDataFiltered={this.props.filterEnabled && this.props.filterCatsCount > 0}>

                    { this.renderContent() }

                </AppToolbar>
            </Menu>
        );
    }
}

// way to determine if we are showing the list
// of aircrafts categories mostly a wild guess
function isOnGeneralAircraftList(props) {
    if (!props.inputs) {
        return false;
    }

    const [ input ] = props.inputs;

    return (
        props.inputs.length === 1 &&
        Object.keys(input).length === 1 &&
        input.dataType === DATA_TYPE_AIRCRAFT_CATEGORIES
    );
}

function isOnProgrammeRootPage(props) {
    if (!props.contextualTitle) {
        return false;
    }
    return props.contextualTitle === 'Programme'
}


ListPage.propTypes = {
    inputs         : PropTypes.arrayOf(
                        PropTypes.shape({
                            dataType: PropTypes.oneOf(NON_CATEGORIES_DATA_TYPE.concat(CATEGORIES_DATA_TYPE)).isRequired,
                            id      : PropTypes.number, // not required. used for categories only
                        })
                     ).isRequired,
    items          : PropTypes.object,
    favorites      : PropTypes.object,
    status         : PropTypes.object,
    adConfig       : PropTypes.object,
    currentLang    : PropTypes.string,
    favIconDisabled: PropTypes.bool,
    hasShowOnMapButton: PropTypes.bool,
    hasGoToSynopticButton: PropTypes.bool,
    customStateOnMap: PropTypes.object,
    // Filter related props
    isFilterVisible: PropTypes.bool,
    hasFilter: PropTypes.bool,
    filterEnabled: PropTypes.bool,
    filterCatsCount: PropTypes.number,
    // Common page props
    isMenuOpen        : PropTypes.bool.isRequired,
    profile           : PropTypes.string,
    labels            : PropTypes.object.isRequired,
    actions           : PropTypes.object.isRequired,
    isActive          : PropTypes.func.isRequired,
    contextualTitle   : PropTypes.string,
    header            : PropTypes.string,
    setDocumentContext: PropTypes.func.isRequired,
    isLocationEnabled : PropTypes.bool,
    searchResults     : PropTypes.object,
    // toolbar
    hasToolbar           : PropTypes.bool,
    homeButtonInToolbar  : PropTypes.bool,
    backButtonInToolbar  : PropTypes.bool,
    searchButtonInToolbar: PropTypes.bool,
    favButtonInToolbar   : PropTypes.bool,
    menuButtonInToolbar  : PropTypes.bool,
    rootCategoryButtonInToolbar: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[LIST_PAGE_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListPage);
